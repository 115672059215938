import debounce from "lodash.debounce";
import { useEffect, useState } from "react";

const BASE_DELAY = 100;

export const useWindowSize = (delay = BASE_DELAY) => {
  const [windowSize, setWindowSize] = useState({
    screenWidth: undefined,
    screenHeight: undefined,
  });
  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      });
    }, delay);

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
