import useIsSmallScreen from "../../hooks/useIsSmallScreen";

const RefreshButton = () => {
  const isSmallScreen = useIsSmallScreen();

  const refreshHandler = () => {
    window.location.reload();
  };

  return (
    <>
      {isSmallScreen && (
        <div onClick={refreshHandler} className="menu-item">
          refresh
        </div>
      )}
    </>
  );
};

export default RefreshButton;
