import { toCleanupCache } from "../helpers/cache";

const withCleanup = (store) =>
  store.subscribe(() => {
    toCleanupCache.forEach(({ cleanup, actionType }) => {
      if (store.getState().lastAction.type === actionType) {
        cleanup();
      }
    });
  });

export default withCleanup;
