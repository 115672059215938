import { createSlice } from "@reduxjs/toolkit";
import { TOASTR_TYPE } from "../constants/toastr";

const INITIAL_STATE = {
  text: "",
  isOpen: false,
  type: "",
};

const toastrSlice = createSlice({
  name: "toastr",
  initialState: INITIAL_STATE,
  reducers: {
    openToastr(state, action) {
      const { text, type } = action.payload;
      state.text = text;
      state.type = type || TOASTR_TYPE.SUCCESS;
      state.isOpen = true;
    },
    closeToastr(state) {
      state.isOpen = false;
    },
  },
});

export const { openToastr, closeToastr } = toastrSlice.actions;

export default toastrSlice.reducer;
