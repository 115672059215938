import React, { useState } from "react";
import { useSelector } from "react-redux";

import { CATEGORIES, TAGS } from "../../constants/store";

import DropDownElement from "./DropDownElement";

const ToolsDropDown = ({ value, dropdownItems, onClick }) => {
  const isStoreAdmin = useSelector((state) => state?.user?.user?.is_store_admin);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const label = isDropdownOpen ? "-" : "+";

  const dropdaownList = isStoreAdmin
    ? dropdownItems
    : dropdownItems.filter(({ label }) => label !== TAGS && label !== CATEGORIES);

  const show = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div className="dropdown-root">
      <div className="dropdown-placeholder" onClick={show}>
        {value}
        <span className="dropdown-label">{label}</span>
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          {dropdaownList.map((item) => (
            <DropDownElement key={item.link} item={item} onClick={onClick} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ToolsDropDown;
