export const DIFF_COEFFICIENTS = { DEAD_LIFT: 4.5, SQUAT: 4, BENCH: 3 };
export const GOAL_SLIDER_STATE = { DATE: "date", WEEK: "week", DAY: "day" };
export const GOAL_FAT_MULTIPLIER = 27;
export const GOAL_WEIGHT_MULTIPLIER = 2.2;
export const INITIAL_GOAL_DATE_OFFSET = 84;
export const MIN_CLIENT_WEIGHT_LB = 80;
export const MAX_CLIENT_WEIGHT_LB = 550;
export const MIN_CLIENT_WEIGHT_KG = 20;
export const MAX_CLIENT_WEIGHT_KG = 250;

export const BODY_REQUESTS = {
  UPDATE_GOAL_DATE: "UPDATE_GOAL_DATE",
  UPDATE_GOAL_VALUE: "UPDATE_GOAL_VALUE",
  UPDATE_BODY: "UPDATE_BODY",
  UPDATE_STRENGTH: "UPDATE_STRENGTH",
};
export const GRAPH_TYPE = { CURRENT: "current", GOAL: "goal" };
