import loadable from "@loadable/component";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CacheBuster from "./CacheBuster";

import GuardedRoute from "./components/GuardedRoute";
import MainLayout from "./components/MainLayout";
import { Popup } from "./components/Popup/Popup";
import Spinner from "./components/Spinner";
import { USER_ROLES } from "./constants/common";
import { USER_TYPE_COMPANY_ADMIN } from "./constants/payment";
import {
  AUTH,
  CLIENT,
  COMPANY,
  EXERCISES,
  PAYMENT,
  PROFILE,
  TRAINERS,
  PROGRAM_TEMPLATES,
  FOODS,
  HOME,
  TERMS,
  PRIVACY,
  CREATE_ACCOUNT,
  CONFIRM_EMAIL,
  ACCOUNT_VALIDATION,
  FIRST_LOGIN,
  CATEGORIES,
  TAGS,
} from "./constants/router";
import { useSearchParam } from "./hooks/useSearchParam";
import PageNotFound from "./pages/PageNotFound";
import {
  loadCompanyById,
  loadPaymentAccount,
  deletePaymentAccount,
  setPaymentAccountLoadingAttempted,
} from "./redux/companiesSlice";
import { store } from "./redux/index";
import { clearSuccessLoadInfo, loadProfile } from "./redux/userSlice";
import { useCheckEndUserPayment } from "./hooks/useCheckEndUserPayment";
import { GA_MEASUREMENT_ID } from "./env/env";
import ReactGA from "react-ga4";

const Auth = loadable(() => import("./pages/Auth"));
const RestorePassword = loadable(
  () => import("./pages/Auth/AuthPage/restorePassword")
);
const Client = loadable(() => import("./pages/Clients"));
const Company = loadable(() => import("./pages/Companies"));
const Exercises = loadable(() => import("./pages/Exercises"));
const Categories = loadable(() => import("./pages/Categories"));
const Tags = loadable(() => import("./pages/Tags"));
const Foods = loadable(() => import("./pages/Foods"));
const Home = loadable(() => import("./pages/Home"));
const Payment = loadable(() => import("./pages/Payment"));
const Privacy = loadable(() => import("./pages/Privacy/Privacy"));
const Profile = loadable(() => import("./pages/Profile/Index"));
const ProgramTemplates = loadable(() => import("./pages/ProgramTemplates"));
const Terms = loadable(() => import("./pages/Terms/Index"));
const Trainers = loadable(() => import("./pages/Trainers"));
const CreateAccount = loadable(() => import("./pages/CreateAccount"));
const ConfirmEmailPage = loadable(() => import("./pages/ConfirmEmail"));
const AccountValidationPage = loadable(
  () => import("./pages/AccountValidation")
);
const FirstLoginPage = loadable(() => import("./pages/FirstLogin"));

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const currentCompany = useSelector(
    (state) => state.companies?.currentCompany
  );
  const successfullyLoadedUserInfo = useSelector((state) => state.user.success);

  const isClient = user?.role === USER_ROLES.CLIENT;
  const isTrainer = user?.role === USER_ROLES.TRAINER;
  const isCompanyAdmin = user?.role === USER_ROLES.COMPANY_ADMIN;

  useEffect(() => {
    if (successfullyLoadedUserInfo) {
      dispatch(clearSuccessLoadInfo());
    }
  }, [successfullyLoadedUserInfo]);

  if (GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        };
        return (
          <Router>
            <MainLayout>
              <Switch>
                <Route path={AUTH.root}>
                  <Auth />
                </Route>
                <Route path={CREATE_ACCOUNT}>
                  <CreateAccount />
                </Route>
                <Route path={CONFIRM_EMAIL}>
                  <ConfirmEmailPage />
                </Route>
                <Route path={ACCOUNT_VALIDATION}>
                  <AccountValidationPage />
                </Route>
                <Route path={AUTH.newPassword}>
                  <RestorePassword />
                </Route>
                <Route path={TERMS}>
                  <Terms />
                </Route>
                <Route path={PRIVACY}>
                  <Privacy />
                </Route>
                <Route path={CLIENT.root}>
                  <NeedAuth key={1}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <Client />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={CATEGORIES.root}>
                  <NeedAuth key={12}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={!isClient}
                    >
                      <Categories />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={EXERCISES.root}>
                  <NeedAuth key={2}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={!isClient}
                    >
                      <Exercises />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={TAGS.root}>
                  <NeedAuth key={2}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={!isClient}
                    >
                      <Tags />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={COMPANY.root}>
                  <NeedAuth key={3}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={!isClient && !isCompanyAdmin && !isTrainer}
                    >
                      <Company />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={TRAINERS.root}>
                  <NeedAuth key={4}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={
                        !isClient &&
                        !isTrainer &&
                        !(isCompanyAdmin && !currentCompany?.can_add_coaches)
                      }
                    >
                      <Trainers />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={PAYMENT.root}>
                  <NeedAuth key={5}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={!isClient && !isCompanyAdmin && !isTrainer}
                    >
                      <Payment />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={PROFILE.root}>
                  <NeedAuth key={7}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <Profile />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={PROGRAM_TEMPLATES.root}>
                  <NeedAuth key={8}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={!isClient}
                    >
                      <ProgramTemplates />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={FOODS.root}>
                  <NeedAuth key={9}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userRoleGuard={!isClient}
                    >
                      <Foods />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route exact path={HOME.root}>
                  <NeedAuth key={6}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <Home />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={FIRST_LOGIN.root}>
                  <NeedAuth key={10}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <FirstLoginPage />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path="">
                  <NeedAuth key={11}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <PageNotFound />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
              </Switch>
            </MainLayout>
          </Router>
        )
      }}
    </CacheBuster>
  );
}

const NeedAuth = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const isCardVerified = useSelector((state) => state.companies.isCardVerified);
  const paymentAccountLoadingAttempted = useSelector(
    (state) => state.companies.paymentAccountLoadingAttempted
  );
  const [isShowSpinner, setIShowSpinner] = useState(true);
  const [ckoSessionId, _] = useSearchParam("cko-session-id");

  useCheckEndUserPayment();

  useEffect(() => {
    async function checkAuth() {
      if (!user) {
        const userResponse = await dispatch(loadProfile());
        if (typeof userResponse.payload !== "undefined") {
          const userCompany = userResponse.payload?.company;
          if (userCompany && userCompany !== currentCompany?.id) {
            await dispatch(loadCompanyById({ id: userCompany }));
          }
        }
      }
      setIShowSpinner(false);
    }

    checkAuth();
  }, []);

  function checkPaymentAcc() {
    if (
      currentCompany !== null &&
      !ckoSessionId &&
      !isCardVerified &&
      !paymentAccountLoadingAttempted &&
      user?.role === USER_TYPE_COMPANY_ADMIN
    ) {
      dispatch(loadPaymentAccount({ id: currentCompany.id }))
        .then((paymentAcc) => {
          const { payment_account_verified, last4 } = paymentAcc.payload;

          store.dispatch(setPaymentAccountLoadingAttempted(true));
          return { payment_account_verified, last4 };
        })
        .then(({ payment_account_verified, last4 }) => {
          if (payment_account_verified === false && last4 !== null) {
            dispatch(
              deletePaymentAccount({ id: currentCompany.id, forceDelete: true })
            );
          };
        });
    };
  };
  useEffect(() => checkPaymentAcc(), [currentCompany]);

  if (isShowSpinner) {
    return <Spinner />;
  }
  return (
    <>
      {children}
      <Popup />
    </>
  );
};

export default App;
