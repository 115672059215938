import { CACHE_TIMEOUT } from "../constants/api";
import { EError } from "../constants/enums";
import { sessionId } from "../constants/errorLocation";
import { BG_FILE } from "../constants/store";
import withCache from "../helpers/cache";
import { setErrorStatusCode } from "../redux/companiesSlice";

import myFetch, { emailHandlerError } from "./myFetch";

export const loadCompaniesPicker = () => {
  return myFetch("/api/companies/picker/");
};

export const loadCompaniesPickerWithCache = withCache(loadCompaniesPicker, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadCompanies = () => {
  return myFetch("/api/companies/");
};

export const loadCompaniesWithCache = withCache(loadCompanies, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const addCompany = ({ body }) => {
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(
    "/api/companies/",
    { method: "POST", body: body },
    emailHandlerError
  );
};

export const loadCompanyById = ({ id }) => {
  return myFetch(`/api/companies/${id}/`);
};

export const loadCompanyByIdWithCache = withCache(loadCompanyById, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const deleteCompanyById = ({ id }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(`/api/companies/${id}/`, { method: "DELETE" });
};

export const editCompany = ({ id, body }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(
    `/api/companies/${id}/`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );
};

export const addThemeCompany = ({ id, file }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  const formData = new FormData();
  formData.append(BG_FILE, file);
  return myFetch(`/api/companies/${id}/`, {
    method: "PATCH",
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteThemeCompany = ({ id }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  return myFetch(`/api/companies/${id}/`, {
    method: "PATCH",
    body: { bg_file: null },
  });
};

export const createPaymentAccount = ({ body }) => {
  return myFetch("/api/payments/account/", {
    method: "POST",
    body,
  });
};

export const createStripePaymentAccount = ({ body }) => {
  return myFetch("/api/payments/stripe_account/", {
    method: "POST",
    body,
  });
};

export const loadPaymentAccount = () => {
  return myFetch("/api/payments/account/");
};

export const loadStripePaymentAccount = () => {
  return myFetch("/api/payments/stripe_account/");
};

export const payCurrentDue = ({ id, debt }) => {
  return myFetch("/api/payments/pay-debt/", {
    method: "POST",
    body: {
      debt,
    },
  });
};

export const deletePaymentAccount = ({ forceDelete }) => {
  return myFetch(
    "/api/payments/account/",
    {
      method: "DELETE",
    },
    (errors) => {
      if (errors.status === 500) {
        return errors.status;
      }
      if (Array.isArray(errors.non_field_errors)) {
        return errors.non_field_errors[0] || EError.defaultError;
      }
      return EError.defaultError;
    },
    null,
    forceDelete
  );
};

export const deleteStripePaymentAccount = ({ forceDelete }) => {
  return myFetch(
    "/api/payments/stripe_account/",
    {
      method: "DELETE",
    },
    null,
    null,
    forceDelete
  );
};

export const verifyPaymentAccount = () => {
  return myFetch("/api/payments/verify/", {
    method: "POST",
    body: {},
  });
};

export const checkSessionId = async (
  { cko_session_id },
  { rejectWithValue, dispatch }
) => {
  try {
    const response = await myFetch(
      "api/payments/verify_session/",
      {
        method: "POST",
        body: {
          cko_session_id,
        },
      },
      null,
      sessionId
    );

    if (response.throwWithStatusCode) {
      throw new Error(response.message);
    }
    return response;
  } catch (error) {
    dispatch(setErrorStatusCode(400));
    return rejectWithValue(error.message);
  }
};

const loadCompaniesForActivityWidget = ({ page = null, search = null }) => {
  const pageProp = page ? `page=${page}` : "";
  const searchProp = search ? `search=${search}` : "";

  const props = `${pageProp ? pageProp : ""}${
    searchProp && pageProp ? "&" + searchProp : searchProp ? searchProp : ""
  }`;

  return myFetch(`/api/companies/page-list/${props && `?${props}`}`);
};

export const loadCompaniesForActivityWidgetWithCash = withCache(
  loadCompaniesForActivityWidget,
  {
    invalidateTimeout: CACHE_TIMEOUT,
  }
);
