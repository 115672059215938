export const header = [
  {
    label: "",
    style: { width: "4%" },
  },
  {
    label: "category hierarchy",
    style: { width: "53%" },
  },
  {
    label: "programs",
    style: { width: "2%" },
  },
  {
    label: "homepage",
    style: { width: "1%" },
  },
];

export const textFieldSettingsEmpty = {
  options: [],
};

export const textFieldSettings = {
  options: ["blockType", "inline", "list", "textAlign", "link"],
  inline: {
    inDropdown: false,
    options: ["bold", "italic"],
  },
  blockType: {
    inDropdown: true,
    options: ["paragraph", "H1", "Blockquote"],
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered"],
  },
  textAlign: {
    inDropdown: false,
    options: ["left", "center", "right"],
  },
  link: {
    inDropdown: false,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link"],
  },
};

export const DELETE_CATEGORY = "delete category";
export const CATEGORY_PROGRAMS = "category programs";

export const RESPONSE_MSG = {
  CATEGORY_POSTED: "category has been successfully posted in store",
  REMOVED_FROM_STORE: "category has been successfully removed from store",
  CATEGORY_CREATED: "category has been successfully created",
  CATEGORY_EDITED: "category has been successfully edited",
  CATEGORY_DELETED: "category has been successfully deleted",
  ORDER_CHANGED: "categories order has been successfully changed",
  TAG_CREATED: "tag has been successfully created",
  TAG_EDITED: "tag has been successfully edited",
  TAG_DELETED: "tag has been successfully deleted",
  PROGRAM_EDITED: "program has been successfully edited",
  PROGRAM_CREATED: "program has been successfully created",
};

export const VALIDATION_MESSAGE = {
  EMPTY_DESCRIPTION: "public description is a required field",
  EMPTY_CATEGORY_NAME: "category name is a required field",
  EMPTY_TAG_NAME: "tag name is a required field",
  EMPTY_RANK: "you need to pick a rank for fot the tag",
};
