import { CLIENT_INFO_TYPES } from "../constants/clientApi";
import withCache from "../helpers/cache";
import { loadClientByIdWithCache } from "./clientApi";
import myFetch from "./myFetch";

export const loadDietHistory = (clientId) => {
  return myFetch(`/api/clients/${clientId}/diet-plan-history/`);
};

export const loadDietHistoryWithCache = withCache(loadDietHistory);

const invalidateClientDietCache = (id) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.DIETS_PAGE_INIT,
  });
  loadDietHistoryWithCache.invalidateByArgs(id);
};

export const updateDietPlan = ({ clientId, body }) => {
  invalidateClientDietCache(String(clientId));
  return myFetch(`/api/clients/${clientId}/diet-plan-last/`, {
    method: "PATCH",
    body,
  });
};

export const addSecondDiet = ({ clientId, copyFromPrimary }) => {
  invalidateClientDietCache(String(clientId));
  return myFetch(`/api/clients/${clientId}/diet-plan-last/second-diet/`, {
    method: "POST",
    body: { copy_from_primary: copyFromPrimary },
  });
};

export const deleteSecondDiet = ({ clientId }) => {
  invalidateClientDietCache(String(clientId));
  return myFetch(`/api/clients/${clientId}/diet-plan-last/second-diet/`, {
    method: "DELETE",
  });
};

export const addDietFood = ({ clientId, isPrimaryDiet, food }) => {
  invalidateClientDietCache(String(clientId));
  loadClientByIdWithCache.invalidateByArgs({
    id: +clientId,
    infoType: CLIENT_INFO_TYPES.DIETS_PAGE_INIT,
  });
  const selectedDiet = isPrimaryDiet ? "primary-diet" : "second-diet";
  return myFetch(`/api/clients/${clientId}/${selectedDiet}/foods/`, {
    method: "POST",
    body: { food: food.id },
  });
};

export const editDietFood = ({ clientId, isPrimaryDiet, foodId, body }) => {
  invalidateClientDietCache(String(clientId));
  loadClientByIdWithCache.invalidateByArgs({
    id: +clientId,
    infoType: CLIENT_INFO_TYPES.DIETS_PAGE_INIT,
  });
  const selectedDiet = isPrimaryDiet ? "primary-diet" : "second-diet";
  return myFetch(`/api/clients/${clientId}/${selectedDiet}/foods/${foodId}/`, {
    method: "PATCH",
    body,
  });
};

export const deleteDietFood = ({ clientId, isPrimaryDiet, foodId }) => {
  invalidateClientDietCache(String(clientId));
  loadClientByIdWithCache.invalidateByArgs({
    id: +clientId,
    infoType: CLIENT_INFO_TYPES.DIETS_PAGE_INIT,
  });
  const selectedDiet = isPrimaryDiet ? "primary-diet" : "second-diet";
  return myFetch(`/api/clients/${clientId}/${selectedDiet}/foods/${foodId}/`, {
    method: "DELETE",
  });
};

export const loadDietFromTemplate = ({ clientId, templateId }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id: +clientId,
    infoType: CLIENT_INFO_TYPES.DIETS_PAGE_INIT,
  });
  loadDietHistoryWithCache.invalidateByArgs(clientId);
  return myFetch(`/api/clients/${clientId}/diet-plan-last/`, {
    method: "POST",
    body: {
      diet_plan_template_id: templateId,
    },
  });
};

export const restoreDiet = ({ clientId, dietPlan }) => {
  loadDietHistoryWithCache.invalidateByArgs(clientId);
  return myFetch(`/api/clients/${clientId}/diet-plan-history/`, {
    method: "POST",
    body: {
      diet_plan_id: dietPlan.id,
    },
  });
};

export const loadDietTemplates = () => {
  return myFetch("/api/diet-templates/");
};

export const loadDietTemplatesWithCache = withCache(loadDietTemplates);

export const addDietTemplates = (body) => {
  loadDietTemplatesWithCache.invalidate();
  return myFetch("/api/diet-templates/", {
    method: "POST",
    body: body,
  });
};

export const deleteDietTemplates = (clientId) => {
  loadDietTemplatesWithCache.invalidate();
  return myFetch(`/api/diet-templates/${clientId}/`, {
    method: "DELETE",
  });
};
