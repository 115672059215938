import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getCurrenciesApi, setSelectedCurrencyApi } from "../api/currenciesApi";

export const getCurrencies = createAsyncThunk(
  "currencies/getCurrencies",
  () => getCurrenciesApi(),
)

export const setSelectedCurrency = createAsyncThunk(
  "currencies/setSelectedCurrency",
  (args) =>  setSelectedCurrencyApi(args),
);

const currenciesSlice = createSlice({
  name: "currencies",
  initialState: {
    currencies: null,
    isLoaded: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getCurrencies.pending]: (state) => {
      state.isLoaded = false;
    },
    [getCurrencies.fulfilled]: (state, action) => {
      state.isLoaded = true;
      state.currencies = action.payload;
    },
    [getCurrencies.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [setSelectedCurrency.pending]: (state) => {
      state.isLoaded = false;
    },
  }
});

export default currenciesSlice.reducer;