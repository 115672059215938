import { useSelector } from "react-redux";

import { CLIENTS_ITEMS } from "../components/Menu/Menu.model";

export const useFilteredMenuItems = () => {
  const user = useSelector((state) => state.user.user);
  const isEndUser = user?.is_end_user;
  const filteredMenu = !isEndUser
    ? CLIENTS_ITEMS
    : CLIENTS_ITEMS.filter(({ label }) => label !== "body" || label !== "diet");

  return filteredMenu;
};
