import axios from "axios";
import Cookies from "js-cookie";

import { AUTH_ERRORS, JSON_HEADERS, PERMISSION_ERRORS } from "../constants/api";

import {
  ERRORS_DETAIL,
  ERRORS_MESSAGE,
  ERRORS_STATUSES,
} from "../constants/auth";
import { FORM_ERRORS } from "../constants/common";
import { NOT_FOUND } from "../constants/router";
import { sessionId } from "../constants/errorLocation";
import { checkNestedField, prepareHeaders } from "../helpers";

const myFetch = async (url, options, handlerError = null, errorLocation = null, forceDelete = false) => {
  const method = options?.method || "GET";
  const headers = prepareHeaders({headers: options?.headers, method: options?.method, JSON_HEADERS});
  const response = await axios({
    validateStatus: false,
    method: method,
    url: url,
    data: options?.body,
    headers,
    withCredentials: true,
  });
  const responseDataDetail = response.data?.detail;

  if (AUTH_ERRORS.includes(responseDataDetail)) {
    Cookies.remove("user_id")
    Cookies.remove("token")
    return;
  }

  if(forceDelete) {
    response.forceDelete = forceDelete;
  }

  if (PERMISSION_ERRORS.includes(responseDataDetail)) {
    if (!document.location.href.includes(NOT_FOUND)) {
      document.location.href = NOT_FOUND;
    }
    return;
  }

  if (response.status < 200 || response.status >= 300) {
    const errors = {
      ...response.data,
      detail: responseDataDetail || "",
      status: response.status,
    };

    if(errorLocation === sessionId) {
      return verificationHandlerError(errors);
    }

    if (handlerError) {
      throw Error(handlerError(errors));
    }
    throw simpleHandlerError(errors);
  }

  try {
    response.data.status = response.status;
    return response.data;
  } catch (e) {
    return response;
  }
};

export const emailHandlerError = (errors) => {
  if (checkNestedField(errors, "email")) {
    return FORM_ERRORS.EMAIL_ALREADY_USED;
  }
  return simpleHandlerError(errors);
};

export const verificationHandlerError = (errors) => {
  if(+errors.status === 400) {
    return { message: simpleHandlerError(errors), throwWithStatusCode: true };
  }
  throw Error("Something went wrong :(");
}

export const activateClientError = (errors) => {
  if (errors?.detail) {
    return errors?.detail;
  }
  return simpleHandlerError(errors);
};

export const authHandlerError = (errors) => {
  if (
    errors.detail === ERRORS_DETAIL.NOT_ACTIVATED ||
    errors.detail === ERRORS_DETAIL.USER_IS_NOT_ACTIVATED) {
    return ERRORS_MESSAGE.NOT_ACTIVATED;
  }
  if (
    errors.detail === ERRORS_DETAIL.NOT_FOUND ||
    errors.status === ERRORS_STATUSES.UNAUTHORIZED_NUMBER
  ) {
    return ERRORS_MESSAGE.NOT_FOUND;
  }
  return simpleHandlerError(errors);
};

export const restorePasswordHandlerError = (errors) => {
  if (errors.detail === "Not found.") {
    errors.detail = "User with this email doesn't exist.";
  }
  return simpleHandlerError(errors);
};

export const sendBodyTestHandlerError = (errors) => {
  // TODO: написать красивые ошибки
  return simpleHandlerError(errors);
};

export const undefinedHandlerError = () => {
  return "Something went wrong :(";
}

export const simpleHandlerError = (errors) => {
  if (typeof errors[Object.keys(errors)[0]] === 'string')
    return errors[Object.keys(errors)[0]];
  return errors[Object.keys(errors)[0]][0];
};
export default myFetch;
