const addInSortArray = (arr, data, compareValue = "name") => {
  if (!Array.isArray(arr) || arr.length === 0) return [data];
  let addIndex = binarySearch(arr, data, compareValue);
  let copy = [...arr];
  copy.splice(addIndex, 0, data);
  return copy;
};

function binarySearch(arr, data, compareValue) {
  let first = 0;
  let last = arr.length - 1;
  let position = arr.length;
  let found = false;
  let middle;

  if (data[compareValue] <= arr[first][compareValue]) return first;

  while (found === false && first <= last) {
    middle = Math.floor((first + last) / 2);
    if (
      arr[middle][compareValue] >= data[compareValue] &&
      arr[middle - 1][compareValue] < data[compareValue]
    ) {
      found = true;
      position = middle;
    } else if (
      arr[middle][compareValue] >= data[compareValue] &&
      arr[middle - 1][compareValue] > data[compareValue]
    ) {
      last = middle - 1;
    } else {
      first = middle + 1;
    }
  }
  return position;
}

export default addInSortArray;
