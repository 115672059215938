import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { loadClientActivityWithCash, loadClientListWithCache } from "../api/clientApi";

export const loadClientListThunk = createAsyncThunk(
  "clientList/loadClientListThunk",
  (args) => loadClientListWithCache.call(args)
);

export const loadClientActivityThunk = createAsyncThunk(
  "clientList/loadClientActivityThunk",
  (args) => loadClientActivityWithCash.call(args)
);

const clientListSlice = createSlice({
  name: "clientList",
  initialState: {
    clientForDelete: null,
    clients: null,
    totalCount: 0,
    filter: "",
    loading: false,
    success: false,
    error: "",
    name: "",
    clientsActivity: [],
    currentClient: null,
    page: 1,
    prev: null,
    next: null,
  },
  reducers: {
    setForDeleteClient(state, action) {
      state.clientForDelete = action.payload;
    },
    clearClientForDelete(state) {
      state.clientForDelete = null;
    },
    clearFilter(state) {
      state.filter = "";
    },
    clearClientList(state) {
      state.clients = null;
    },
    clearError(state) {
      state.error = "";
    },
    setIsLoading(state) {
      state.loading = true;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
  },
  extraReducers: {
    [loadClientListThunk.pending]: (state) => {
      state.loading = true;
    },
    [loadClientListThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";

      state.clients = action.payload?.results;
      state.page = action.meta.arg.page || 1;
      state.totalCount = action.payload?.count;
      state.next = action.payload?.next;
      state.prev = action.payload?.previous;
    },
    [loadClientListThunk.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [loadClientActivityThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.clientsActivity = action.payload.results;
      state.totalCount = action.payload.count;
      state.prev = action.payload.prev;
      state.next = action.payload.next;
    },
    [loadClientActivityThunk.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  setForDeleteClient,
  clearClientForDelete,
  clearFilter,
  clearClientList,
  clearError,
  setIsLoading,
  setPage
} = clientListSlice.actions;

export default clientListSlice.reducer;
