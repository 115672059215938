import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import thunk from "redux-thunk";

import authSlice from "./authSlice";
import withCleanup from "./cleanup";
import clientListSlice from "./clientListSlice";
import clientSlice from "./clientSlice";
import companiesSlice from "./companiesSlice";
import dietPageSlice from "./dietPageSlice";
import dietTemplatesSlice from "./dietTemplatesSlice";
import exercisesSlice from "./exercisesSlice";
import foodsSlice from "./foodsSlice";
import paymentSlice from "./paymentSlice";
import popupSlice from "./popupSlice";
import programTemplatesSlice from "./programTemplatesSlice";
import programsSlice from "./programsSlice"
import restorePasswordSlice from "./restorePasswordSlice";
import signUpSlice from "./signUpSlice";
import toastrSlice from "./toastrSlice";
import trainersSlice from "./trainersSlice";
import userSlice from "./userSlice";
import utilsSlice from "./utilsSlice";
import windowSlice from "./windowSlice";
import currenciesSlice from "./currenciesSlice";
import storeSlice from "./storeSlice";

const lastAction = (_ = null, action) => {
  return action;
}

const appReducer = combineReducers({
  lastAction,
  clientList: clientListSlice,
  toastr: toastrSlice,
  window: windowSlice,
  companies: companiesSlice,
  user: userSlice,
  trainers: trainersSlice,
  client: clientSlice,
  programTemplates: programTemplatesSlice,
  auth: authSlice,
  forgotPassword: restorePasswordSlice,
  exercises: exercisesSlice,
  dietPage: dietPageSlice,
  foods: foodsSlice,
  dietTemplates: dietTemplatesSlice,
  programs: programsSlice,
  payment: paymentSlice,
  utils: utilsSlice,
  signUp: signUpSlice,
  popup: popupSlice,
  currencies: currenciesSlice,
  store: storeSlice,
});

export const resetStore = () => {
  return {
    type: "Clear"
  }
}

const rootReducer = (state, action) => {
  if (action.type === "Clear") {
    state = undefined;
  }

  return appReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  enhancers: [sentryReduxEnhancer]
});

withCleanup(store);
