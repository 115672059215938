import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import isNumber from "lodash/isNumber";

import * as StoreApi from "../api/storeApi";
import { EError } from "../constants/enums";
import { SIDEBAR_MODE } from "../constants/sidebar";
import { removeCategory, updateCategory, transformArrayToObject } from "../helpers/store";
import { RESPONSE_MSG } from "../pages/Categories/CategoriesPage/helpers/constants";
import { CATEGORIES, NOT_FOUND, TAGS } from "../constants/store";

export const loadCategories = createAsyncThunk(
  "store/loadCategories",
  (args) => StoreApi.loadCategoriesWithCache.call(args)
);

export const loadCategory = createAsyncThunk(
  "store/loadCategory",
  (args) => StoreApi.loadCategoryWithCache.call(args)
);

export const addCategory = createAsyncThunk(
  "store/addCategory",
  (args) => StoreApi.addCategory(args)
);

export const editCategories = createAsyncThunk(
  "store/editCategories",
  (args) => StoreApi.editCategories(args)
);

export const deleteCategory = createAsyncThunk(
  "store/deleteCategory",
  (args) => StoreApi.deleteCategory(args)
);

export const changeCategoriesOrder = createAsyncThunk(
  "store/changeCategoriesOrder",
  (args) => StoreApi.changeCategoriesOrder(args)
);

export const getProgramTemplatesList = createAsyncThunk(
  "store/getProgramTemplatesList",
  (args) => StoreApi.getProgramTemplatesListWithCache.call(args)
);

export const postInStore = createAsyncThunk(
  "store/postInStore",
  (args) => StoreApi.postInStore(args)
);

export const loadTags = createAsyncThunk(
  "store/loadTags",
  (args) => StoreApi.loadTagsWithCache.call(args)
);

export const loadTagTemplatesList = createAsyncThunk(
  "store/loadTagTemplatesList",
  (args) => StoreApi.loadTagTemplatesListWithCache.call(args)
);

export const addTag = createAsyncThunk(
  "store/addTag",
  (args) => StoreApi.addTag(args)
);

export const updateTag = createAsyncThunk(
  "store/updateTag",
  (args) => StoreApi.updateTag(args)
);

export const deleteTag = createAsyncThunk(
  "store/deleteTag",
  (args) => StoreApi.deleteTag(args)
);

export const loadProgramByTemplate = createAsyncThunk(
  "store/loadProgramByTemplate",
  (args) => StoreApi.loadProgramByTemplateWithCache.call(args)
);

export const createProgram = createAsyncThunk(
  "store/createProgram",
  (args) => StoreApi.createProgram(args)
);

export const updateProgram = createAsyncThunk(
  "store/updateProgram",
  (args) => StoreApi.updateProgram(args)
);

export const deleteProgram = createAsyncThunk(
  "store/deleteProgram",
  (args) => StoreApi.deleteProgram(args)
);

export const storeSlice = createSlice({
  name: "store",
  initialState: {
    loading: false,
    widgetLoading: false,
    categoriesList: [],
    programTemplatesList: [],
    editableCategory: {
      title: "",
      id: "",
      bg_file: "",
      public_description: "",
      parent_category: "",
      has_sub_categories: false,
    },
    pickedProgram: {
      title: "",
      id: "",
    },
    parent_category: "",
    error: "",
    success: false,
    sidebarMode: "",
    categoryForRemoval: null,
    needOrderUpdate: false,
    tags: [],
    tagTemplatesList: [],
    program: {
      id: "",
      bg_file: "",
      public_description: "",
      categories: null,
      tags: null,
      is_visible: false,
      program_template: "",
    },
    coverTemplate: "",
    hasNoProgram: true,
  },
  reducers: {
    setCoverTemplate(state, action) {
      state.coverTemplate = action.payload;
    },
    setInitaialTagsAndCategories(state, action) {
      const tags = transformArrayToObject(action.payload.tags, TAGS);
      const categories = transformArrayToObject(action.payload.categories, CATEGORIES);

      state.program.tags = tags;
      state.program.categories = categories;
      state.program.program_template = action.payload.id;
    },
    setHasNoProgram(state, action) {
      state.hasNoProgram = action.payload;
    },
    clearProgram(state) {
      state.program = {
        id: "",
        bg_file: "",
        public_description: "",
        categories: [],
        tags: [],
      };
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setNeedOrderUpdate(state, action) {
      state.needOrderUpdate = action.payload;
    },
    clearCategoriesList(state) {
      state.categoriesList = [];
    },
    setCategoryForRemoval(state, action) {
      state.categoryForRemoval = action.payload;
    },
    setCategories(state, action) {
      state.categoriesList = action.payload;
    },
    clearEditableCategory(state) {
      Object.keys(current(state.editableCategory)).forEach((item) => {
        state.editableCategory[item] = "";
      });
    },
    setSidebarMode(state, action) {
      state.sidebarMode = action.payload;
    },
    setPickedProgram(state, action) {
      state.pickedProgram.title = action.payload.title;
      state.pickedProgram.id = action.payload.id;
    },
    clearError(state) {
      state.error = "";
    },
    clearSuccessMessage(state) {
      state.successMessage = "";
    },
    handleProgram(state, action) {
      state.program.tags = transformArrayToObject(action.payload.tags, TAGS);
      state.program.categories = transformArrayToObject(action.payload.categories, CATEGORIES);
      state.program.id = action.payload.id;
      state.program.bg_file = action.payload.bg_file;
      state.program.is_visible = action.payload.is_visible;
      state.program.public_description = action.payload.public_description;
      state.program.program_template = action.payload.program_template
    },
  },
  extraReducers: {
    [loadProgramByTemplate.pending]: (state) => {
      state.widgetLoading = true;
    },
    [loadProgramByTemplate.fulfilled]: (state, action) => {
      storeSlice.caseReducers.handleProgram(state, action);
      state.error = "";
      state.widgetLoading = false;
      state.hasNoProgram = false;
    },
    [loadProgramByTemplate.rejected]: (state, action) => {
      state.hasNoProgram = true;
      if (action.error?.message !== NOT_FOUND) {
        state.widgetLoading = false;
      };
    },
    [createProgram.pending]: (state) => {
      state.widgetLoading = true;
    },
    [createProgram.fulfilled]: (state, action) => {
      storeSlice.caseReducers.handleProgram(state, action);
      state.error = "";
      state.successMessage = RESPONSE_MSG.PROGRAM_CREATED;
      state.widgetLoading = false;
      state.hasNoProgram = false;
    },
    [createProgram.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.widgetLoading = false;
    },
    [updateProgram.pending]: (state) => {
      state.widgetLoading = true;
    },
    [updateProgram.fulfilled]: (state, action) => {
      storeSlice.caseReducers.handleProgram(state, action);
      state.error = "";
      state.successMessage = RESPONSE_MSG.PROGRAM_EDITED;
      state.widgetLoading = false;
    },
    [updateProgram.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.widgetLoading = false;
    },
    [deleteProgram.pending]: (state) => {
      state.widgetLoading = true;
    },
    [deleteProgram.fulfilled]: (state) => {
      state.program = {};
      state.error = "";
      state.widgetLoading = false;
    },
    [deleteProgram.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.widgetLoading = false;
    },
    [loadCategories.pending]: (state) => {
      state.loading = true;
    },
    [loadCategories.fulfilled]: (state, action) => {
      state.categoriesList = action.payload;
      state.error = "";
      state.loading = false;
      state.widgetLoading = false;
    },
    [loadCategories.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
    },
    [loadCategory.pending]: (state) => {
      state.widgetLoading = true;
    },
    [loadCategory.fulfilled]: (state, action) => {
      state.editableCategory = action.payload;
      state.error = "";
      state.widgetLoading = false;
    },
    [loadCategory.rejected]: (state, action) => {
      state.widgetLoading = false;
      state.error = action.payload || EError.default_error;
    },
    [postInStore.fulfilled]: (state, action) => {
      const itemsForHandling =
        updateCategory(current(state.categoriesList), action.payload);
      state.categoriesList = itemsForHandling;

      state.successMessage = action.payload.is_visible
        ? RESPONSE_MSG.CATEGORY_POSTED
        : RESPONSE_MSG.REMOVED_FROM_STORE;
      state.error = "";
    },
    [postInStore.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
    },
    [addCategory.pending]: (state) => {
      state.loading = true;
      state.widgetLoading = true;
    },
    [addCategory.fulfilled]: (state, action) => {
      if (!isNumber(action.payload.parent_category)) {
        state.categoriesList.push({
          ...action.payload,
          sub_categories: [],
        });
      } else {
        state.categoriesList.forEach((item) => {
          if (item.id === action.payload.parent_category) {
            item.sub_categories.push(action.payload);
          };
        });
      };
      state.successMessage = RESPONSE_MSG.CATEGORY_CREATED;
      state.error = "";
      state.loading = false;
      state.widgetLoading = false;
    },
    [addCategory.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
      state.widgetLoading = false;
    },
    [editCategories.pending]: (state) => {
      state.loading = true;
      state.widgetLoading = true;
    },
    [editCategories.fulfilled]: (state, action) => {
      state.editableCategory = action.payload;
      state.successMessage = RESPONSE_MSG.CATEGORY_EDITED;
      state.error = "";
    },
    [editCategories.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
      state.widgetLoading = false;
    },
    [deleteCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteCategory.fulfilled]: (state, payload) => {
      const updatedList =
        removeCategory(payload.meta.arg.id, current(state.categoriesList));
      state.categoriesList = updatedList;
      state.categoryForRemoval = null;
      state.sidebarMode = SIDEBAR_MODE.ADD;
      state.successMessage = RESPONSE_MSG.CATEGORY_DELETED;
      clearEditableCategory();
      state.error = "";
      state.loading = false;
    },
    [deleteCategory.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
    },
    [changeCategoriesOrder.fulfilled]: (state) => {
      state.successMessage = RESPONSE_MSG.ORDER_CHANGED;
      state.error = "";
    },
    [changeCategoriesOrder.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
    },
    [getProgramTemplatesList.pending]: (state) => {
      state.widgetLoading = true;
    },
    [getProgramTemplatesList.fulfilled]: (state, action) => {
      state.programTemplatesList = action.payload;
      state.error = "";
      state.widgetLoading = false;
    },
    [getProgramTemplatesList.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.widgetLoading = false;
    },
    [loadTags.pending]: (state) => {
      state.loading = true;
    },
    [loadTags.fulfilled]: (state, action) => {
      state.tags = action.payload;
      state.error = "";
      state.loading = false;
    },
    [loadTags.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
    },
    [loadTagTemplatesList.pending]: (state) => {
      state.sidebarMode = SIDEBAR_MODE.TAG_PROGRAMS;
      state.widgetLoading = true;
    },
    [loadTagTemplatesList.fulfilled]: (state, action) => {
      state.tagTemplatesList = action.payload;
      state.error = "";
      state.widgetLoading = false;
    },
    [loadTagTemplatesList.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.widgetLoading = false;
    },
    [addTag.pending]: (state) => {
      state.loading = true;
      state.widgetLoading = true;
    },
    [addTag.fulfilled]: (state, action) => {
      state.tags = [ ...state.tags, action.payload];
      state.successMessage = RESPONSE_MSG.TAG_CREATED;
      state.error = "";
      state.loading = false;
      state.widgetLoading = false;
    },
    [addTag.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
      state.widgetLoading = false;
    },
    [updateTag.pending]: (state) => {
      state.widgetLoading = true;
      state.loading = true;
    },
    [updateTag.fulfilled]: (state, action) => {
      state.tags = state.tags.map((tag) => {
        if (tag.id === action.payload.id) {
          return {
            ...action.payload,
            program_templates_count: tag.program_templates_count,
          };
        };
        return tag;
      });
      state.successMessage = RESPONSE_MSG.TAG_EDITED;
      state.error = "";
      state.widgetLoading = false;
      state.loading = false;
    },
    [updateTag.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.widgetLoading = false;
      state.loading = false;
    },
    [deleteTag.pending]: (state) => {
      state.loading = true;
    },
    [deleteTag.fulfilled]: (state, action) => {
      state.tags = state.tags.reduce((acc, tag) => {
        return +action.meta.arg.id !== +tag.id
        ? [...acc, tag]
        : acc;
      }, []);
      state.successMessage = RESPONSE_MSG.TAG_DELETED;
      state.error = "";
      state.loading = false;
    },
    [deleteTag.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
    },
  }
});

export const {
  clearCategoriesList,
  setCategories,
  clearEditableCategory,
  setSidebarMode,
  setCategoryForRemoval,
  setPickedProgram,
  clearError,
  clearSuccessMessage,
  setNeedOrderUpdate,
  setHasNoProgram,
  clearProgram,
  setInitaialTagsAndCategories,
  setCoverTemplate,
  setError,
} = storeSlice.actions;

export default storeSlice.reducer;
