import React from "react";
import { Link } from "react-router-dom";

import { COMPANY, PAYMENT, PROFILE, TRAINERS } from "../../../constants/router";
import { useFilteredMenuItems } from "../../../hooks/useFilteredMenuItems";
import ClientDropDown from "../ClientDropDown";
import { SUPERUSER_TOOLS_ITEMS } from "../Menu.model";
import ToolsDropDown from "../ToolsDropDown";
import RefreshButton from "../RefreshButton";

const SuperUserMenu = ({ closeMenuAndResetEditableClientId, closeMenu }) => {
  const filteredMenu = useFilteredMenuItems();

  return (
    <>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={PROFILE.root} className="tools">
          my profile
        </Link>
      </div>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={PAYMENT.root} className="tools">
          payment schedule
        </Link>
      </div>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={COMPANY.root} className="tools">
          companies
        </Link>
      </div>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={TRAINERS.root} className="tools">
          coaches
        </Link>
      </div>
      <ClientDropDown dropdownItems={filteredMenu} onClick={closeMenu} />
      <ToolsDropDown
        onClick={closeMenuAndResetEditableClientId}
        dropdownItems={SUPERUSER_TOOLS_ITEMS}
        value="tools"
      />
      <RefreshButton/>
    </>
  );
};

export default React.memo(SuperUserMenu);
