import "./Spinner.scss";

import { Box, CircularProgress } from "@material-ui/core";

const Spinner = ({ stickToTop, ...props }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems={stickToTop ? "bottom" : "center"}
    height="100%"
    width="100%"
    position="absolute"
    top="0"
    left="0"
    {...props}
  >
    <CircularProgress size="80px" />
  </Box>
);

export default Spinner;
