export const REP_CALCULATOR_TYPES = {
  STANDART: "Standard",
  BACK_BICEP: "Back/Bicep",
  LEG_PRESS_EXT: "Leg Press/Ext"
}

export const coefficients =  {
  [REP_CALCULATOR_TYPES.STANDART]: [
    1.0000,
    1.0363,
    1.0753,
    1.1111,
    1.1429,
    1.1765,
    1.2048,
    1.2346,
    1.2579,
    1.2821,
    1.3072,
    1.3333,
    1.3605,
    1.3831,
    1.4085,
    1.4286,
    1.4493,
    1.4706,
    1.4925,
    1.5152,
    1.5385,
    1.5545,
    1.5708,
    1.5873,
    1.6044,
    1.6218,
    1.6393,
    1.6529,
    1.6667,
    1.6667,
  ],
  [REP_CALCULATOR_TYPES.BACK_BICEP]: [
    1.0000,
    1.0256,
    1.0526,
    1.0753,
    1.0989,
    1.1236,
    1.1494,
    1.1737,
    1.1976,
    1.2195,
    1.2422,
    1.2626,
    1.2837,
    1.3004,
    1.3158,
    1.3298,
    1.3441,
    1.3587,
    1.3736,
    1.3889,
    1.4045,
    1.4205,
    1.4368,
    1.4535,
    1.4706,
    1.4859,
    1.5015,
    1.5152,
    1.5267,
    1.5385,
  ],
  [REP_CALCULATOR_TYPES.LEG_PRESS_EXT]: [
    1.0000,
    1.0152,
    1.0309,
    1.0471,
    1.0638,
    1.0787,
    1.0941,
    1.1099,
    1.1261,
    1.1429,
    1.1561,
    1.1696,
    1.1834,
    1.1976,
    1.2121,
    1.2240,
    1.2361,
    1.2484,
    1.2610,
    1.2739,
    1.2853,
    1.2970,
    1.3089,
    1.3210,
    1.3333,
    1.3459,
    1.3587,
    1.3717,
    1.3850,
    1.3986,
    1.4124,
    1.4265,
    1.4409,
    1.4556,
    1.4706,
    1.4837,
    1.4970,
    1.5106,
    1.5244,
    1.5385,
    1.5504,
    1.5625,
    1.5748,
    1.5873,
    1.6000,
    1.6129,
    1.6260,
    1.6393,
    1.6529,
    1.6667,
  ]
}