import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import menuIcon from "../../assets/icons/menu.png";
import logoLite from "../../assets/images/recomposer-lite.png";
import logo from "../../assets/images/recomposerapp.png";
import { ENDUSER_COMPANY } from "../../constants/common";
import { setShowMenu } from "../../redux/windowSlice";
import "./Header.scss";

const Header = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const showMenu = useSelector((state) => state.window.showMenu);
  const showHeader = useSelector((state) => state.window.showHeader);

  const companyId = 1; // TODO: получать из redux  //const { companyId } = useSelector(state => ...)
  const isEndUserClient = companyId === ENDUSER_COMPANY.ID;
  const authorized = !!user;
  const isEndUser = user?.is_end_user;
  const firstLogin =
    isEndUser && (!user?.is_strength_exists || !user?.is_program_exists);
  const hideMenu = firstLogin || !authorized;
  const showRecomposerLiteLogo = isEndUser || !authorized;

  const toggleMenu = () => {
    dispatch(setShowMenu(true));
  };

  const logoSrc = (isEndUserClient || showRecomposerLiteLogo) ? logoLite : logo;

  return (
    <div>
      {showHeader && (
        <header id="main-header" className="main-header">
          {!showMenu && !hideMenu && (
            <img
              alt="Menu icon"
              className="main-header__menu"
              onClick={toggleMenu}
              src={menuIcon}
            />
          )}
          {user && (
            <>
              <img
                alt="Logo"
                className={cn("main-header__logo", showRecomposerLiteLogo ? "lite" : "")}
                src={logoSrc}
              />
              <div className="main-header__version">v3</div>
            </>
          )}
        </header>
      )}
    </div>
  );
};

export default Header;
