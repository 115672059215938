import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as PaymentApi from "../api/paymentAPI";

export const loadPaymentList = createAsyncThunk("payment/loadPaymentList", (args) =>
 PaymentApi.loadPaymentListWithCache.call(args)
);

export const loadCompanyPaymentList = createAsyncThunk(
  "payment/loadCompanyPaymentList",
  (args) => PaymentApi.loadCompanyPaymentListWithCache.call(args)
);

export const loadEndUserPaymentList = createAsyncThunk(
  "payment/loadEndUserPaymentList",
  (args) => PaymentApi.loadEndUserPaymentList(args)
);

export const loadPaymentInvoiceDays = createAsyncThunk(
  "payment/loadPaymentInvoiceDays",
  (args) => PaymentApi.loadPaymentInvoiceDaysWithCache.call(args)
);

export const updatePayment = createAsyncThunk(
  "payment/updatePayment",
  PaymentApi.updatePayment
);

const paymentSlice = createSlice({
  name: "paymentList",
  initialState: {
    loading: false,
    success: false,
    error: "",
    list: null, // loadPaymentList
    listByCompany: {}, // key - company.id, value - loadCompanyPaymentList
    listByDays: {}, // key - invoice.id, value - loadPaymentInvoiceDays
    currentPayment: null,
    successUpdate: null,
    count: null,
    next: null,
    previous: null,
  },
  reducers: {
    setSuccessUpdate(state, action) {
      state.successUpdate = action.payload;
    },
    setCurrentPayment(state, action) {
      state.currentPayment = action.payload;
    },
    clearCurrentPayment(state) {
      state.currentPayment = null;
    },
    changeCurrentPayment(state, action) {
      state.currentPayment = { ...state.currentPayment, ...action.payload };
    },
    changeElInList(state, action) {
      state.list = state.list.map((el) => {
        if (el.id === action.payload.id) return action.payload;
        return el;
      });
    },
    clearError(state) {
      state.error = "";
    },
  },
  extraReducers: {
    [loadPaymentList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadPaymentList.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.list = action.payload.results;
      // state.list = action.payload;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
    },
    [loadPaymentList.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error;
    },
    [loadCompanyPaymentList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadCompanyPaymentList.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.listByCompany = { ...state.listByCompany, ...action.payload };
    },
    [loadCompanyPaymentList.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error;
    },
    [loadEndUserPaymentList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadEndUserPaymentList.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.listByCompany = { ...state.listByCompany, ...action.payload };
    },
    [loadEndUserPaymentList.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error;
    },
    [loadPaymentInvoiceDays.pending]: (state) => {
      state.error = null;
    },
    [loadPaymentInvoiceDays.fulfilled]: (state, action) => {
      state.success = true;
      state.error = null;
      state.listByDays = { ...state.listByDays, ...action.payload };
    },
    [loadPaymentInvoiceDays.rejected]: (state, action) => {
      state.success = false;
      state.error = action.error;
    },
    [updatePayment.pending]: (state) => {
      state.loading = true;
      state.successUpdate = false;
    },
    [updatePayment.fulfilled]: (state, action) => {
      state.loading = false;
      state.successUpdate = true;
      state.list = state.list.reduce((acc, item) => {
        if (item.id === state.currentPayment.id) {
          const { discount, received, sent_at } = action.payload;
          if ((+received + +discount) === state.currentPayment.invoice_total) return acc;
          return [
            ...acc,
            {
              ...item,
              discount,
              received,
              sent_at,
              owing: state.currentPayment.invoice_total - received - discount,
            },
          ];
        }
        return [...acc, item];
      }, []);
    },
  },
});

export const {
  setSuccessUpdate,
  setCurrentPayment,
  clearCurrentPayment,
  changeCurrentPayment,
  changeElInList,
  clearError,
} = paymentSlice.actions;

export default paymentSlice.reducer;
