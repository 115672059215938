export const ID_SLUG = ":id";

export const DIET = "/diet";
export const PRIVACY = "/privacy";
export const TERMS = "/terms";

export const CREATE_ACCOUNT = "/create-account";
export const CONFIRM_EMAIL = "/confirm-email";
export const ACCOUNT_VALIDATION = "/account-validation";
export const NOT_FOUND = "/404";

export const BODY_PAGE_SLUG = "/body";
export const BODY_TEST_RESULT_PAGE_SLUG = `${BODY_PAGE_SLUG}/test-result`;
export const BODY_PROGRESS_PAGE_SLUG = `${BODY_PAGE_SLUG}/progress`;
export const TRAINING_PROGRAM_SLUG = "/training/program";
export const CLIENT = {
  root: "/clients/",
  addClient: "add-client/",
  clientInfo: ":id/",
  bodyClient: `:id${BODY_PAGE_SLUG}/`,
  bodyTestResult: `:id${BODY_TEST_RESULT_PAGE_SLUG}`,
  diet: `:id${DIET}`,
  bodyProgress: `:id${BODY_PROGRESS_PAGE_SLUG}`,
  trainingProgram: `:id${TRAINING_PROGRAM_SLUG}`,
};

export const PROGRAM_TEMPLATES = {
  root: "/program-templates/",
  addProgramTemplate: "add-program-template/",
};

export const MANAGE_COMPANY_SLUG = "/manage";
export const COMPANY = {
  root: "/companies/",
  addCompany: "add-company/",
  manageCompany: `:id${MANAGE_COMPANY_SLUG}`,
};

export const AUTH = {
  root: "/auth/",
  newPassword: "/sessions/new-password/",
};

export const PROFILE = {
  root: "/profile/",
};

export const EXERCISES = {
  root: "/exercises/",
};

export const FOODS = {
  root: "/foods/",
};

export const TRAINERS = {
  root: "/coaches/",
};

export const PAYMENT = {
  root: "/payment/",
};

export const HOME = {
  root: "/",
};

export const CATEGORIES = {
  root: "/categories/"
};

export const FIRST_LOGIN = {
  root: "/first-login",
  welcomePage: "welcome/",
  startingStrength: "starting-strength/",
  firstProgram: "first-program/",
};

export const TAGS = {
  root: "/tags/"
}
