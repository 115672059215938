export const IS_VISIBLE = "is_visible";
export const ID = "id";
export const CATEGORIES = "categories";
export const TAGS = "tags";
export const BG_FILE = "bg_file";
export const PUBLIC_DESCRIPTION = "public_description";
export const PROGRAM_TEMPLATE = "program_template";
export const PARENT_CATEGORY = "parent_category";

export const REDIRECTED_FROM_CATEGORIES = "&from=categories";
export const REDIRECTED_FROM_TAGS = "&from=tags";

export const NOT_FOUND = "Not found.";
export const PATHNAME = "pathname";

export const WIDGET_STORE = "?widget=store";

export const TITLE = "title";

export const LABELS = {
  CATEGORY_NAME: "category name",
  PUBLIC_DESCRIPTION: "public description",
  FEATURED_IMAGE: "featured image",
  PARENT_CATEGORY: "parent category",
};

export const ADD_PROGRAM_PAGE_PATH = "add-program-template";
