import last from "lodash/last";
import round from "lodash/round";

import {
  FOR_DAYS_OPTIONS,
  MAX_FOODS_PREVIEW,
  MAX_MEAL_COLUMN_COUNT,
  MIN_MEAL_COLUMN_COUNT,
  NON_TRAINING,
  TRAINING,
} from "../constants/diet";

const createMeals = (foods) => {
  let mealsFood = [...Array(MAX_MEAL_COLUMN_COUNT).keys()].map((i) => ({
    id: i,
    name: `meal ${i + 1}`,
    foods: [],
  }));
  const otherFood = [],
    woFood = [];
  let isHaveWo = false,
    maxMealCount = MIN_MEAL_COLUMN_COUNT;
  for (let item of foods) {
    let isMealIdx = [];
    const { meals } = item;
    for (let [idx, isMeal] of meals.entries()) {
      isMeal && isMealIdx.push(idx);
    }
    let mealCount = isMealIdx.length;
    let maxMealsPos = last(isMealIdx) + 1;
    if (maxMealsPos > maxMealCount) {
      maxMealCount = maxMealsPos;
    }

    if (item.is_pre_post_workout) {
      mealCount++;
      if (!isHaveWo) {
        isHaveWo = true;
      }
    }

    if (!mealCount) {
      otherFood.push({
        food: item.food,
        quantity: item.quantity,
      });
      continue;
    }

    let quantity = item.quantity / mealCount;

    for (let idx of isMealIdx) {
      const mealIndex = mealsFood.findIndex((x) => x.id === idx);
      mealsFood[mealIndex].foods.push({
        food: item.food,
        quantity,
      });
    }

    if (item.is_pre_post_workout) {
      woFood.push({
        food: item.food,
        quantity,
      });
    }
  }
  mealsFood.push({
    id: MAX_MEAL_COLUMN_COUNT + 1,
    name: "other food",
    foods: otherFood,
  });
  mealsFood.push({
    id: MAX_MEAL_COLUMN_COUNT + 2,
    name: "pre/post workout",
    foods: woFood,
  });
  return {
    mealsFood: mealsFood.filter((m) => m.foods.length),
    isHaveWo,
    maxMealCount,
  };
};

export const prepareDietState = (diets) => {
  const mealColumnCount = [],
    isShowAllFoods = [],
    isShowPrePostWorkout = [],
    meals = [];
  diets.forEach((diet) => {
    if (diet?.foods === undefined) return;
    const { foods } = diet;
    const { mealsFood, isHaveWo, maxMealCount } = createMeals(foods);
    meals.push(mealsFood);
    isShowPrePostWorkout.push(isHaveWo);
    mealColumnCount.push(maxMealCount);
    isShowAllFoods.push(diet?.foods.length <= MAX_FOODS_PREVIEW);
  });
  return {
    isShowPrePostWorkout,
    mealColumnCount,
    meals,
    diets,
    isShowAllFoods,
  };
};

export const getForDaysLabel = (value, diets, dietIndex) => {
  let label;
  if (value === TRAINING || value === NON_TRAINING) {
    return `${value} days`;
  }
  const total = diets.reduce((sum, diet) => ({
    for_days: parseInt(sum.for_days) + parseInt(diet.for_days),
  })).for_days;
  const singleDay = value === "1";
  label = `${singleDay ? "day" : "days"}`;
  if (dietIndex === 0) {
    return `${label} 1 ${!singleDay ? `to ${value} ` : ""}of ${total}`;
  }
  return `${label} ${parseInt(diets[0].for_days) + 1} ${
    !singleDay ? `to ${total} ` : ""
  }of ${total}`;
};

export const forDaysToOption = (forDays) => {
  return FOR_DAYS_OPTIONS.find((option) => option.value === forDays);
};

export const getDietForDays = (dietCurrentForDays, updatedForDays) => {
  switch (updatedForDays) {
    case TRAINING:
      return NON_TRAINING;
    case NON_TRAINING:
      return TRAINING;
    default:
      const forDays = dietCurrentForDays || FOR_DAYS_OPTIONS[0].value;
      if (forDays === TRAINING || forDays === NON_TRAINING) {
        return FOR_DAYS_OPTIONS[0].value;
      }
      return forDays;
  }
};

/**
 * @param {{
 *   proteins: number;
 *   carbs: number;
 *   fats: number;
 * }} percentage
 * @return {string}
 */
export const dietPercentageToString = (percentage) => {
  if (!percentage) return "0:0:0";

  const { proteins, carbs, fats } = percentage;
  return `${round(proteins)}:${round(carbs)}:${round(fats)}`
};
