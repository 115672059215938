import { createSlice } from "@reduxjs/toolkit";

const popupSlice = createSlice({
  name: "popup",
  initialState: {
    show: false,
    content: {
      title: null,
      description: null,
      buttonTitle: null,
      onClick: () => {},
    },
  },
  reducers: {
    setShowPopup(state, action) {
      state.show = action.payload;
    },
    setPopupContent(state, action) {
      state.content = action.payload;
    },
  },
});

export const { setShowPopup, setPopupContent } = popupSlice.actions;

export default popupSlice.reducer;
