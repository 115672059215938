import { CACHE_TIMEOUT } from "../constants/api";
import withCache from "../helpers/cache";

import myFetch, { emailHandlerError } from "./myFetch";

const loadTrainersPicker = (companyId) => {
  const param = companyId ? `company_id=${companyId}` : "";
  return myFetch(`/api/trainers/picker/?${param}`);
};

const loadTrainers = ({ page, search = "", companyId }) => {
  const params = `page=${page}${!!search ? `&search=${search}` : ""}${
    companyId ? `&company_id=${companyId}` : ""
  }`;
  return myFetch(`/api/trainers/?${params}`);
};

const loadTrainerById = (id) => {
  return myFetch(`/api/trainers/${id}/`, {
    method: "GET",
  });
};

const loadActiveClients = async (id) => {
  const clients = await myFetch(`/api/trainers/${id}/active_clients/`, {
    method: "GET",
  });
  return { [id]: clients };
};

const loadAllClients = async (id) => {
  const clients = await myFetch(`/api/trainers/${id}/all_clients/`, {
    method: "GET",
  });
  return { [id]: clients };
};

export const loadTrainersPickerWithCache = withCache(loadTrainersPicker, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadTrainersWithCache = withCache(loadTrainers, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadTrainerByIdWithCache = withCache(loadTrainerById, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadActiveClientsWithCache = withCache(loadActiveClients, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadAllClientsWithCache = withCache(loadAllClients, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const addTrainer = (trainer) => {
  loadTrainersPickerWithCache.invalidate();
  loadTrainersWithCache.invalidate();
  loadTrainerByIdWithCache.invalidateByArgs(trainer);
  loadActiveClientsWithCache.invalidateByArgs(trainer);
  loadAllClientsWithCache.invalidateByArgs(trainer);
  return myFetch(
    "/api/trainers/",
    {
      method: "POST",
      body: trainer,
    },
    emailHandlerError
  );
};

export const editTrainer = ({ id, body }) => {
  loadTrainersPickerWithCache.invalidate();
  loadTrainersWithCache.invalidate();
  loadTrainerByIdWithCache.invalidateByArgs(id);
  loadActiveClientsWithCache.invalidateByArgs(id);
  loadAllClientsWithCache.invalidateByArgs(id);
  return myFetch(
    `/api/trainers/${id}/`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );
};

export const deleteTrainer = ({ id }) => {
  loadTrainersPickerWithCache.invalidate();
  loadTrainersWithCache.invalidate();
  return myFetch(`/api/trainers/${id}/`, { method: "DELETE" });
};
