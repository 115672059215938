import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import "./Popup.scss";

const popupContainer = document.getElementById("popup-container");

export const Popup = () => {
  const showPopup = useSelector((state) => state.popup.show);
  const popupContent = useSelector((state) => state.popup.content);

  const el = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    popupContainer.appendChild(el);

    return () => {
      popupContainer.removeChild(el);
    };
  });

  return (
    showPopup &&
    createPortal(
      <div className="popup-backdrop">
        <div className="popup__inner">
          <div className="popup">
            <h2 className="popup__header">{popupContent.title}</h2>
            <div className="popup__body">{popupContent.description}</div>
            {popupContent.onClick && (
              <div className="popup__footer">
                <button
                  className="default-btn popup__btn"
                  onClick={popupContent.onClick}
                >
                  {popupContent?.buttonTitle}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>,
      el
    )
  );
};
