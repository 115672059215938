import { Drawer } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Menu.scss";
import { Link } from "react-router-dom";

import menuCloseIcon from "../../assets/images/menu__close-btn.png";
import { USER_ROLES } from "../../constants/common";
import { TERMS, PRIVACY } from "../../constants/router";
import { clearClient } from "../../redux/clientSlice";
import { setShowMenu } from "../../redux/windowSlice";

import ClientMenu from "./ClientMenu";
import CompanyAdminMenu from "./CompanyAdminMenu";
import SuperUserMenu from "./SuperUserMenu";
import TrainerMenu from "./TrainerMenu";
import useLocalStorage from "../../hooks/useLocalStorage";

const Menu = () => {
  const dispatch = useDispatch();
  const showMenu = useSelector((state) => state.window.showMenu);
  const closeMenu = () => {
    dispatch(setShowMenu(false));
  };

  const [_, setPreviousUrl] = useLocalStorage("prevUrl", "");

  useEffect(() => {
    if (
      window.location.pathname !== "/terms" &&
      window.location.pathname !== "/privacy"
    ) {
      setPreviousUrl(window.location.pathname);
    }
  }, [window.location.pathname]);

  const closeMenuAndResetEditableClientId = () => {
    closeMenu();
    dispatch(clearClient());
  };

  const getMenu = (userRole = "default", currentCompany) => {
    const { CLIENT, COMPANY_ADMIN, TRAINER, SUPERUSER } = USER_ROLES;
    const menus = {
      [CLIENT]: <ClientMenu clientId={clientId} clickHandler={closeMenu} />,
      [SUPERUSER]: (
        <SuperUserMenu
          closeMenuAndResetEditableClientId={closeMenuAndResetEditableClientId}
          closeMenu={closeMenu}
        />
      ),
      [COMPANY_ADMIN]: (
        <CompanyAdminMenu
          closeMenuAndResetEditableClientId={closeMenuAndResetEditableClientId}
          closeMenu={closeMenu}
          isCanAddCoaches={currentCompany?.can_add_coaches}
        />
      ),
      [TRAINER]: (
        <TrainerMenu
          closeMenuAndResetEditableClientId={closeMenuAndResetEditableClientId}
          closeMenu={closeMenu}
        />
      ),
      default: (
        <SuperUserMenu
          closeMenuAndResetEditableClientId={closeMenuAndResetEditableClientId}
          closeMenu={closeMenu}
        />
      ),
    };
    return menus[userRole];
  };

  const userRole = useSelector((state) => state.user.user?.role);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const loadingUser = useSelector((state) => state.user.loading);
  const loadingCompanies = useSelector((state) => state.companies.loading);
  const clientId = useSelector((state) => state.user.user?.id);

  return (
    <Drawer open={showMenu} onClose={closeMenu}>
      <div className="menu">
        <img
          className="menu__close-btn"
          src={menuCloseIcon}
          onClick={closeMenu}
          alt="Menu close icon"
          width="24"
          height="24"
        />
        {!(loadingUser || loadingCompanies) &&
          getMenu(userRole, currentCompany)}
        <div className="menu__footer">
          <div onClick={closeMenuAndResetEditableClientId}>
            <Link to={TERMS} className="menu__footer__link">
              t&amp;c's
            </Link>
          </div>
          {" | "}
          <div onClick={closeMenuAndResetEditableClientId}>
            <Link to={PRIVACY} className="menu__footer__link">
              privacy
            </Link>
          </div>
          {" | "}
          <div>
            <a
              href="https://recomposer.com/knowledgebase"
              className="menu__footer__link"
            >
              help
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Menu;
