import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import myFetch from "../api/myFetch";
import { EError } from "../constants/enums";

export const signUpClient = createAsyncThunk("api/signup", (body) => {
  return myFetch("/api/signup", { method: "POST", body: body }, (errors) => {
    if (Array.isArray(errors?.email)) {
      const errorKey = errors?.email[0];
      return EError[errorKey] || EError.defaultError;
    }
    if (Array.isArray(errors.non_field_errors)) {
      return errors.non_field_errors[0] || EError.defaultError;
    }
    return EError.defaultError;
  });
});

export const resendConfirm = createAsyncThunk("api/resentConfirm", (body) => {
  return myFetch(
    "/api/signup/resend-confirm",
    { method: "POST", body: body },
    (errors) => {
      if (Array.isArray(errors.non_field_errors)) {
        return errors.non_field_errors[0] || EError.defaultError;
      }
      return EError.defaultError;
    }
  );
});

export const confirmEmail = createAsyncThunk("api/confirmEmail", (body) => {
  return myFetch(
    "/api/signup/confirm",
    { method: "POST", body: body },
    (errors) => {
      if (errors.status === 403) return errors.status;
      if (Array.isArray(errors.non_field_errors)) {
        return `bad link: ${errors.non_field_errors[0] || EError.defaultError}`;
      }
      return EError.defaultError;
    }
  );
});

const signUpSlice = createSlice({
  name: "signup",
  initialState: {
    loading: false,
    error: false,
    signUpStatus: false,
    uid: null,
    token: null,
    success: false,
    errorMessage: null,
    resendLoading: false,
    resendError: false,
    resendSent: false,
    resendErrorMessage: null,
    confirmEmailLoading: false,
    confirmEmailError: false,
    confirmEmailErrorMessage: null,
    confirmEmailSuccess: null,
    redirectToSignUp: false,
    redirectToResendEmail: false,
  },
  reducers: {
    clearMessage(state) {
      state.error = false;
      state.errorMessage = null;
      state.resendErrorMessage = null;
      state.confirmEmailErrorMessage = null;
      state.resendSent = false;
      state.redirectToSignUp = false;
      state.redirectToResendEmail = false;
    },
    resetSignUpSuccess(state) {
      state.success = false;
    },
  },
  extraReducers: {
    [signUpClient.pending]: (state) => {
      state.signUpStatus = false;
      state.loading = true;
      state.error = false;
      state.success = false;
      state.errorMessage = null;
    },
    [signUpClient.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.signUpStatus = true;
      state.success = true;
      state.errorMessage = null;
    },
    [signUpClient.rejected]: (state, action) => {
      state.signUpStatus = false;
      state.loading = false;
      state.error = true;
      state.success = false;
      state.errorMessage = action.error.message;
    },
    [resendConfirm.pending]: (state) => {
      state.resendLoading = true;
      state.resendError = false;
      state.resendErrorMessage = null;
    },
    [resendConfirm.fulfilled]: (state) => {
      state.resendLoading = false;
      state.resendError = false;
      state.resendSent = true;
      state.resendErrorMessage = null;
    },
    [resendConfirm.rejected]: (state, action) => {
      state.resendSent = false;
      state.resendLoading = false;
      state.resendError = true;
      state.resendErrorMessage = action.error.message;
    },
    [confirmEmail.pending]: (state) => {
      state.confirmEmailLoading = true;
      state.confirmEmailError = false;
      state.confirmEmailSuccess = false;
      state.confirmEmailErrorMessage = null;
      state.redirectToSignUp = false;
    },
    [confirmEmail.fulfilled]: (state) => {
      state.confirmEmailLoading = false;
      state.confirmEmailError = false;
      state.confirmEmailSuccess = true;
      state.redirectToSignUp = false;
      state.confirmEmailErrorMessage = null;
    },
    [confirmEmail.rejected]: (state, action) => {
      const errorMsg = action.error.message;

      state.confirmEmailError = true;
      state.confirmEmailLoading = false;
      state.confirmEmailSuccess = false;
      state.redirectToSignUp = true;

      state.confirmEmailErrorMessage =
        errorMsg === "403" ? EError.expired_error : errorMsg;
    },
  },
});
export const { clearMessage, resetSignUpSuccess } = signUpSlice.actions;
export default signUpSlice.reducer;
