import { useState, useEffect } from "react";

const getStorageValue = (key, initialValue) => {
  const storageValue = localStorage?.getItem(key) || null;

  return storageValue ? JSON.parse(storageValue) : initialValue;
};
const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(getStorageValue(key, initialValue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
