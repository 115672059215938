export const AUTH_ERRORS = [
  "Authentication credentials were not provided.",
  "Wrong user ID.",
  "Wrong token value. Please re-login.",
  "No such user.",
];

export const PERMISSION_ERRORS = [
  "You do not have permission to perform this action.",
];

export const JSON_HEADERS = { "Content-Type": "application/json" };

export const CACHE_TIMEOUT = 300000;
