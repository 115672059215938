import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import DropDownElement from "./DropDownElement";

const ClientDropDown = ({ onClick, dropdownItems }) => {
  const client = useSelector((state) => state.client.client);

  return (
    <div className="dropdown-root">
      <div className="menu-item">
        <Link onClick={onClick} to="/clients" className="tools">
          clients
        </Link>
      </div>
      {client?.id &&
        dropdownItems.map((item) => (
          <DropDownElement
            key={item.link}
            id={client?.id}
            item={item}
            onClick={onClick}
            disabled={!client.is_active && item.needActive}
          />
        ))}
    </div>
  );
};

export default ClientDropDown;
