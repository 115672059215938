import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { paymentFailed, trialExpired } from "../constants/popup";
import { PROFILE } from "../constants/router";
import { store } from "../redux";
import { setPopupContent, setShowPopup } from "../redux/popupSlice";
import {
  deleteUserPaymentAccount,
  loadUserPaymentAccount,
  setUserPaymentAccountLoadingAttempted,
} from "../redux/userSlice";

import { useSearchParam } from "./useSearchParam";

export const useCheckEndUserPayment = () => {
  const { push } = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const haveToPay = useSelector((state) => state.user.haveToPay);
  const haveToSaveCard = useSelector((state) => state.user.haveToSaveCard);
  const isCardVerified = useSelector((state) => state.user.isCardVerified);
  const preventUserPaymentAccRemoval = useSelector(
    (state) => state.user.preventUserPaymentAccRemoval
  );
  const paymentAccountLoadingAttempted = useSelector(
    (state) => state.user.paymentAccountLoadingAttempted
  );

  const showPopup = useSelector((state) => state.popup.show);

  const [ckoSessionId, _] = useSearchParam("cko-session-id");

  const on404Page = match.path.includes("404");
  const onProfilePage = match.path.includes(PROFILE.root);

  const firstLogin = !user?.is_program_exists || !user.is_strength_exists;

  const showPaymentPopup =
    !onProfilePage &&
    !on404Page &&
    (haveToSaveCard || haveToPay) &&
    !firstLogin;

  useEffect(() => {
    if (showPaymentPopup) {
      const popupContent = haveToSaveCard
        ? trialExpired
        : haveToPay
        ? paymentFailed
        : null;

      const onClick = () => {
        push(PROFILE.root);
        dispatch(setShowPopup(false));
      };

      if (popupContent) {
        dispatch(setShowPopup(true));
        dispatch(
          setPopupContent({
            title: popupContent.title,
            description: popupContent.description,
            buttonTitle: popupContent.buttonTitle,
            onClick,
          })
        );
      }
    } else {
      dispatch(setShowPopup(false));
    }
  }, [showPaymentPopup]);

  useEffect(() => {
    if (showPopup && (onProfilePage || on404Page)) {
      dispatch(setShowPopup(false));
    }
  }, [showPopup, onProfilePage, on404Page]);

  async function checkPaymentAcc() {
    if (
      user !== null &&
      user?.is_end_user &&
      !ckoSessionId &&
      !isCardVerified &&
      !paymentAccountLoadingAttempted
    ) {
      const paymentAcc = await dispatch(
        loadUserPaymentAccount({ id: user.id })
      );

      const { payment_account_verified, last4 } = paymentAcc.payload;
      store.dispatch(setUserPaymentAccountLoadingAttempted(true));
      if (payment_account_verified === false && last4 !== null && !preventUserPaymentAccRemoval) {
        await dispatch(
          deleteUserPaymentAccount({ id: user.id, forceDelete: true })
        );
      };
    };
  };

  useEffect(() => {
    if (firstLogin) return;

    checkPaymentAcc();
  }, [user, firstLogin]);
};
