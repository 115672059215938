import { CACHE_TIMEOUT } from "../constants/api";
import { getParams } from "../helpers";
import withCache from "./../helpers/cache";
import myFetch from "./myFetch";

const loadFoods = ({ page, search }) => {
  const params = getParams({ page, search });
  return myFetch(`/api/foods/${params && `?${params}`}`);
};

const loadFavoriteFoods = ({ page }) => {
  const params = getParams({ page });
  return myFetch(`/api/foods/fav/${params && `?${params}`}`)
};

export const loadFavoriteFoodsWithCache = withCache(loadFavoriteFoods, {
  invalidateTimeout: CACHE_TIMEOUT,
});

const loadFoodsForCurrentUser = ({ page, search }) => {
  const params = getParams({ page, search });
  return myFetch(`/api/foods/current-user/${params && `?${params}`}`);
};

export const loadFoodsWithCache = withCache(loadFoods, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadFoodsForCurrentUserWithCache = withCache(
  loadFoodsForCurrentUser,
  {
    invalidateTimeout: CACHE_TIMEOUT,
  }
);

export const addFood = ({ body }) => {
  loadFoodsWithCache.invalidate();
  loadFavoriteFoodsWithCache.invalidate();
  loadFoodsForCurrentUserWithCache.invalidate();
  return myFetch("/api/foods/", { method: "POST", body: body });
};

export const editFood = ({ body, id }) => {
  loadFoodsWithCache.invalidate();
  loadFavoriteFoodsWithCache.invalidate();
  loadFoodsForCurrentUserWithCache.invalidate();
  return myFetch(`/api/foods/${id}/`, { method: "PUT", body: body });
};

export const deleteFood = ({ id }) => {
  loadFoodsWithCache.invalidate();
  loadFavoriteFoodsWithCache.invalidate();
  loadFoodsForCurrentUserWithCache.invalidate();
  return myFetch(`/api/foods/${id}/`, {
    method: "DELETE",
  });
};
