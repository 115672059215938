import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AUTH,
  PROFILE,
  CLIENT,
  BODY_TEST_RESULT_PAGE_SLUG,
  DIET,
  TRAINING_PROGRAM_SLUG,
} from "../../../constants/router";
import { resetStore } from "../../../redux";
import { logoutClient } from "../../../redux/authSlice";
import RefreshButton from "../RefreshButton";

const ClientMenu = ({ clientId, clickHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const isEndUser = user?.is_end_user;
  const showBodyPageForEndUser = user?.show_body_page_for_end_user;
  const showDietPageForEndUser = user?.show_diet_page_for_end_user;

  const logoutHandler = () => {
    dispatch(resetStore());
    dispatch(logoutClient());
    history.push(AUTH.root);
  };

  return (
    <>
      <div onClick={clickHandler} className="menu-item">
        <Link to={PROFILE.root} className="tools">
          my profile
        </Link>
      </div>
      {(!isEndUser || showBodyPageForEndUser) && (
        <div onClick={clickHandler} className="menu-item">
          <Link
            to={CLIENT.root + clientId + BODY_TEST_RESULT_PAGE_SLUG}
            className="tools"
          >
            body
          </Link>
        </div>
      )}
      {(!isEndUser || showDietPageForEndUser) && (
        <div onClick={clickHandler} className="menu-item">
          <Link to={CLIENT.root + clientId + DIET} className="tools">
            diet
          </Link>
        </div>
      )}
      <div onClick={clickHandler} className="menu-item">
        <Link
          to={CLIENT.root + clientId + TRAINING_PROGRAM_SLUG}
          className="tools"
        >
          training
        </Link>
      </div>
      <div className="menu-item" onClick={logoutHandler}>
        logout
      </div>
      <RefreshButton/>
    </>
  );
};

export default React.memo(ClientMenu);
