import myFetch from "./myFetch";

export const getCurrenciesApi = async () => {
  return await myFetch("/api/currencies/");
}

export const setSelectedCurrencyApi = async ({ id, body }) => {
  return await myFetch(`/api/currencies/${id}/`, {
    method: "PATCH",
    body
  })
}