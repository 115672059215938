export const SNACKBAR_PROPS = {
  autoHideDuration: 4000,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export const TOASTR_TYPE = {
  SUCCESS: "success",
  SAVED: "saved",
  ERROR: "error",
};
