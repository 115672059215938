import { CACHE_TIMEOUT } from "../constants/api";
import withCache from "../helpers/cache";
import { prepareRequestData } from "../helpers/store";

import myFetch from "./myFetch";

const loadCategories = () => {
  return myFetch("/api/store/categories/");
};

export const loadCategoriesWithCache = withCache(loadCategories , {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const addCategory = async (values) => {
  loadCategoriesWithCache.invalidate();
  loadProgramByTemplateWithCache.invalidate();
  const formData = new FormData();
  const body = await prepareRequestData(values, formData);

  return myFetch(
    "/api/store/categories/",
    { method: "POST", body: body }
  );
};

export const editCategories = async ({ id, body }) => {
  loadCategoriesWithCache.invalidate();
  loadCategoryWithCache.invalidate();
  loadProgramByTemplateWithCache.invalidate();
  const formData = new FormData();
  const preparedBody = await prepareRequestData(body, formData);

  return myFetch(
    `/api/store/categories/${id}/`,
    { method: "PATCH", body: preparedBody }
  );
};

const loadCategory = ({ id }) => {
  return myFetch(`/api/store/categories/${id}/`);
};

export const loadCategoryWithCache = withCache(loadCategory);

export const deleteCategory = ({ id, body }) => {
  loadCategoriesWithCache.invalidate();
  getProgramTemplatesListWithCache.invalidate();
  loadProgramByTemplateWithCache.invalidate();
  return myFetch(
    `/api/store/categories/${id}/`,
    { method: "DELETE", body: body }
  );
};

export const changeCategoriesOrder = ({ body }) => {
  loadCategoriesWithCache.invalidate();

  return myFetch(
    "/api/store/categories/update-order-indexes/",
    { method: "PATCH", body }
  );
};

const getProgramTemplatesList = ({ id }) => {
  return myFetch(`/api/store/categories/${id}/program-templates-list/`);
};

export const getProgramTemplatesListWithCache = withCache(getProgramTemplatesList);

export const postInStore = ({ id, is_visible }) => {
  loadCategoriesWithCache.invalidate();
  loadCategoryWithCache.invalidate();
  loadProgramByTemplateWithCache.invalidate();
  return myFetch(
    `/api/store/categories/${id}/`,
    { method: "PATCH", body: {
      is_visible,
    }},
  );
};

const loadTags = () => myFetch("/api/store/tags/");

export const loadTagsWithCache = withCache(loadTags, {
  invalidateTimeout: CACHE_TIMEOUT,
});

const loadTagTemplatesList = ({ id }) => {
  return myFetch(`/api/store/tags/${id}/program-templates-list/`);
};

export const loadTagTemplatesListWithCache = withCache(loadTagTemplatesList);

export const addTag = ({ body }) => {
  loadTagsWithCache.invalidate();
  loadProgramByTemplateWithCache.invalidate();
  return myFetch("/api/store/tags/",
    { method: "POST", body },
  );
};

export const updateTag = ({ body, id }) => {
  loadTagsWithCache.invalidate();
  loadProgramByTemplateWithCache.invalidate();
  return myFetch(`/api/store/tags/${id}/`,
    { method: "PATCH", body },
  );
};

export const deleteTag = ({ id }) => {
  loadTagsWithCache.invalidate();
  loadProgramByTemplateWithCache.invalidate();
  return myFetch(`/api/store/tags/${id}`,
    { method: "DELETE" },
  );
};

const loadProgramByTemplate = ({ id }) => {
  return myFetch(`/api/store/programs/${id}/by-program-template/`);
};

export const loadProgramByTemplateWithCache = withCache(loadProgramByTemplate);

export const createProgram = async ({ body }) => {
  loadProgramByTemplateWithCache.invalidate();

  const formData = new FormData();
  const preparedBody = await prepareRequestData(body, formData);

  return myFetch("/api/store/programs/",
    {
      method: "POST",
      body: preparedBody,
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
};

export const updateProgram = async ({ id, body }) => {
  loadProgramByTemplateWithCache.invalidate();

  const formData = new FormData();
  const preparedBody = await prepareRequestData(body, formData);

  return myFetch(`/api/store/programs/${id}/`,
    {
      method: "PATCH",
      body: preparedBody,
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
};

export const deleteProgram = ({ id }) => {
  loadProgramByTemplateWithCache.invalidate();

  return myFetch(`/api/store/programs/${id}/`,
    { method: "DELETE" },
  );
};
