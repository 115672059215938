// dateRegExp is used in client's date of birth, it must be 10 - 100 years from current

const lastDigitCurrentYear = new Date().getFullYear() % 10;
const dateRegExp = {
  day: "[0-2][0-9]|(3)[0-1]",
  month: "((0)[0-9])|((1)[0-2])",
  year: [
    `((192)[${lastDigitCurrentYear}-9])`,
    "((19)[3-9][0-9])",
    "((200)[0-9])",
    `(201)[0-${lastDigitCurrentYear}]`,
  ].join("|"),
};
const { day, month, year } = dateRegExp;

export const REGEXP_DATE = `^(${day})(\\/)(${month})(\\/)(${year})$`;
export const REGEXP_PHONE = /^\+?\d+(?:\(\d+\))?[\d-]*\d$/;
export const REGEXP_EMAIL =
  /^([\w_-]+\.)*[\w_-]+@[\w_-]+(\.[\w_-]+)*\.[a-z]{2,6}$/;
export const REGEXP_NOT_NUM = /[^\d.]/;
export const REGEXP_NUM_REQUIRED = /^[\d]+$/;

export const REGEXP_ANY_FLOAT = /^[\d]+([.,]\d+)?$/;
export const REGEXP_ANY_FLOAT_FIXED_ONE = /^[\d]+([.,]\d)?$/;
export const REGEXP_ANY_FLOAT_FIXED_TWO = /^(?:[1-9][\d]*|0)(?:\.[\d]{0,2})?$/;

export const REGEXP_NOT_INT = /[\D]+/;

export const REGEXP_FORM_INT_NUM = /^[\d\b]+$/;
export const REGEXP_FORM_FLOAT_NUM_FIXED_ONE = /^[\d]{1,7}(?:\.[\d]{0,1})?$/;
export const REGEXP_FORM_FLOAT_NUM_FIXED_TWO = /^[\d]{1,7}(?:\.[\d]{0,2})?$/;
export const REGEXP_FORM_FLOAT_NUM_FIXED_FOUR = /^(?:[1-9][\d]{0,6}|0)(?:\.[\d]{0,4})?$/;
export const REGEXP_EMPTY_STRING = /^\s*$/;
export const REGEXP_NAME = /^[A-Za-z-]+$/;
export const REGEXP_NAME_PLUS_SPACE = /^[a-zA-Z ]*$/;
export const REGEXP_EMAIL_LATIN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const REGEXP_CARD_HOLDER_NAME_TEST =
  /^([a-z]{1,15} [a-z]{1,15} [a-z]{1,15})|([a-z]{1,15} [a-z]{1,15})$/i;
export const REGEXP_CARD_HOLDER_NAME = /^[a-z]{1,15} [a-z]{1,15}$/i;
export const COMPANY_IDS_WITH_ACCESS_MIDDLE_NAME_FEATURE = [
  "61a622dca6070e25eb9cdbd6", // damon recomp
];

export const REGEXP_EMOJI = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/i;
