import withCache from "../helpers/cache";

import myFetch, { undefinedHandlerError } from "./myFetch";

export const loadTraining = ({ id }) => myFetch(`/api/clients/${id}/training/`);

export const getProgram = (props) => {
  const page = +props.programIndex ? `page=${props.programIndex}&` : "";

  return myFetch(`/api/training/programs/?${page}user_id=${props.id}`);
};

export const loadProgramIds = ({ id }) => {
  return myFetch(`/api/training/programs/templates/?user_id=${id}`);
};

export const loadTrainingWithCache = withCache(loadTraining);
export const getProgramWithCache = withCache(getProgram);
export const loadProgramIdsWithCache = withCache(loadProgramIds);

export const addProgram = ({ body }) => {
  const { id } = body;
  getProgramWithCache.invalidateByArgs({ id: +id, programIndex: 1 });
  getProgramWithCache.invalidateByArgs({ id: +id });
  loadProgramIdsWithCache.invalidateByArgs({ id: +id });
  loadTrainingWithCache.invalidateByArgs({ id: +id });
  return myFetch("/api/training/programs/", {
    method: "POST",
    body,
  });
};

export const updateProgram = ({ id, body }) => {
  loadTrainingWithCache.invalidate();
  getProgramWithCache.invalidate();
  return myFetch(
    `/api/training/programs/${id}/`,
    {
      method: "PATCH",
      body,
    },
    undefinedHandlerError
  );
};

export const changeExercise = ({ id, body, clientId }) => {
  getProgramWithCache.invalidateByArgs({
    id: clientId,
  });
  loadTrainingWithCache.invalidateByArgs({ id: clientId });
  return myFetch(`/api/training/change-exercise/${id}/`, {
    method: "PATCH",
    body,
  });
};

export const updateSet = ({ id, body, currentProgramData }) => {
  getProgramWithCache.invalidateByArgs(currentProgramData);
  loadTrainingWithCache.invalidateByArgs({ id });
  return myFetch(`/api/training/update-set/${id}/`, {
    method: "PATCH",
    body,
  }).then((res) => {
    const args = {
      id,
    };

    if (res?.program) {
      const body = {
        ...res.program,
      };
      getProgramWithCache.updateByArgs(args, Promise.resolve(body));
    } else {
      getProgramWithCache.invalidateByArgs(args);
    }

    return res;
  });
};

export const updatePb = ({ body }) => {
  const { user } = body;

  getProgramWithCache.invalidateByArgs({ id: user });
  loadTrainingWithCache.invalidateByArgs({ id: user });
  return myFetch("/api/training/pb/", {
    method: "POST",
    body,
  });
};

export const saveDayNotes = ({ id, body }) => {
  return myFetch("/api/training/notes/", {
    method: "POST",
    body,
  }).then((res) => {
    const args = {
      id,
    };

    if (res?.note) {
      getProgramWithCache(args).then((prog) => {
        const noteInProg = prog.notes.findIndex(
          (note) => note.day_index === res.note.day_index
        );
        // haskell devs be like
        const notes =
          noteInProg !== -1
            ? [
                ...prog.notes.slice(0, noteInProg),
                res.note,
                ...prog.notes.slice(noteInProg + 1),
              ]
            : [...prog.notes, res.note];

        const body = {
          ...prog,
          notes,
        };
        getProgramWithCache.updateByArgs(args, Promise.resolve(body));
      });
    } else {
      getProgramWithCache.invalidateByArgs(args);
    }

    return res;
  });
};

export const loadSetHistory = ({ program_index, day_index, client }) =>
  myFetch(
    `/api/clients/${client}/day-history/?program_index=${program_index}&day_index=${day_index}`
  );

export const loadSetHistoryWithCache = withCache(loadSetHistory);

const loadSetHistoryById = (mode) => (id) =>
  myFetch(`/api/training/set-history/${id}/?mode=${mode}`);

export const loadPreviousSetHistoryById = loadSetHistoryById("overview");

export const loadPreviousSetHistoryByIdWithCache = withCache(
  loadPreviousSetHistoryById
);

export const loadOtherSetHistoryById = loadSetHistoryById("history");

export const loadOtherSetsWithCache = withCache(loadOtherSetHistoryById);
