export const trialExpired = {
  title: "credit card required",
  description:
    "to continue using Recomposer, you need to enter a valid credit card.",
  buttonTitle: "OK"
};

export const paymentFailed = {
  title: "failed payment",
  description:
    "to continue using Recomposer, you must pay with a valid credit card",
  buttonTitle: "Pay now"
};
