import cloneDeep from "lodash/cloneDeep";
import * as Yup from "yup";

import DEFAULT_IMAGE from  "../assets/images/categoryDefaultImg.jpg";
import { FORM_LABELS } from "../constants/common";
import { SIDEBAR_MODE } from "../constants/sidebar";
import { extractTextFromHtml } from ".";
import { VALIDATION_MESSAGE } from "../pages/Categories/CategoriesPage/helpers/constants";
import isEqual from "lodash/isEqual";
import {
  BG_FILE,
  CATEGORIES,
  ID,
  IS_VISIBLE,
  PARENT_CATEGORY,
  PROGRAM_TEMPLATE,
  PUBLIC_DESCRIPTION,
  TAGS,
} from "../constants/store";

export const reorderCategorylist = ({ categoriesList, isSubRow, childIndex, parentIndex }) => {
  let flag;
  const upatedList = cloneDeep(categoriesList);

  if (!isSubRow) {
    flag = upatedList[childIndex];
    upatedList[childIndex] = upatedList[childIndex - 1];
    upatedList[childIndex - 1] = flag;
  } else {
    flag = upatedList[parentIndex].sub_categories[childIndex];
    upatedList[parentIndex].sub_categories[childIndex] = upatedList[parentIndex].sub_categories[childIndex - 1];
    upatedList[parentIndex].sub_categories[childIndex - 1] = flag;
  };

  return upatedList;
};

export const findCategory = (categoriesList, rest) => (
  rest.isSubRow
   ? categoriesList[rest.parentIndex].sub_categories[rest.index]
   : categoriesList[rest.index]
);

export const prepareSelectOptions = (data, parentCategory, sidebarMode) => {
  if (parentCategory === "" && sidebarMode === SIDEBAR_MODE.EDIT) return [];
  const options = data?.map((item) => ({ value: item.id ?? "", label: item.title })) || [];

  options.splice(0, 0, { value: "", label: FORM_LABELS.SELECT_PARENT_CATEGORY });
  return options;
};

export const prepareRequestData = async (values, formData) => {
  for (const key in values) {
    if (values[key] === "" && key === BG_FILE) {
      await fetch(DEFAULT_IMAGE)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "categoryDefaultImg.jpg", { type: "image/jpeg" });
          formData.append(key, file);
        });
      } else if (values[key] !== "" || PARENT_CATEGORY) {
        formData.append(key, values[key]);
    };
  };
  return formData;
};

export const sortCategoriesForOrderUpdate = (categories) => {
  return categories.reduce((acc, item) => {
    const res = {
      id: item.id,
    };

    if (item.sub_categories?.length) {
      res.sub_categories = sortCategoriesForOrderUpdate(item.sub_categories);
    };

    return [...acc, res];
  }, []);
};

export const getUpdatedFields = (initialValues, newValues) => {
  const response = {};
  for (let key in initialValues) {
    if (initialValues[key] !== newValues[key]) {
      response[key] = newValues[key];
    };
  };
  return response;
};

export const updateCategory = (items, editedItem) => {
  const itemsForHandling = cloneDeep(items);

  for (let i = 0; i < itemsForHandling.length; i++) {
    if (editedItem.parent_category === itemsForHandling[i].id) {
      itemsForHandling[i].sub_categories = itemsForHandling[i].sub_categories.map((item) => {
        if (item.id === editedItem.id) {
          return {
            ...editedItem,
            program_templates_count: item.program_templates_count,
          };
        };
        return item;
      });
    };

    if (itemsForHandling[i].id === editedItem.id && !editedItem.parent_category) {
      itemsForHandling[i] = {
        ...editedItem,
        sub_categories: itemsForHandling[i].sub_categories,
        program_templates_count: itemsForHandling[i].program_templates_count,
      };
      break;
    };
  };

  return itemsForHandling;
};

export const removeCategory = (id, arr, parentCat = null) => {
  const prepearedArr = cloneDeep(arr);

  return prepearedArr.filter((item) => {
    if (item.id === id) {
      if (parentCat && Boolean(item.program_templates_count)) {
        --parentCat.program_templates_count;
      };
      return false;
    } else if (item.sub_categories) {
      item.sub_categories = removeCategory(id, item.sub_categories, item);
    };
    return true;
  });
};

export const categoriesValidationSchema = Yup.object().shape({
  public_description: Yup
    .string()
    .required(VALIDATION_MESSAGE.EMPTY_DESCRIPTION)
    .test("custom-validation", VALIDATION_MESSAGE.EMPTY_DESCRIPTION, (value) => {
    return extractTextFromHtml(value).trim();
  }),
  title: Yup.string().required(VALIDATION_MESSAGE.EMPTY_CATEGORY_NAME),
});

export const tagValidationSchema = Yup.object().shape({
  title: Yup.string().required(VALIDATION_MESSAGE.EMPTY_TAG_NAME),
  rank: Yup.string().required(VALIDATION_MESSAGE.EMPTY_RANK),
});

export const setQueryParamsHandler = ({ values, setQueryParam }) => {
  if (setQueryParam) {
    values.forEach((item) => {
      setQueryParam(item[0], item[1], item[2]);
    });
  };
};

export const programValidatiionSchema = Yup.object().shape({
  public_description: Yup
    .string()
    .required(VALIDATION_MESSAGE.EMPTY_DESCRIPTION)
    .test("custom-validation", VALIDATION_MESSAGE.EMPTY_DESCRIPTION, (value) => {
    return extractTextFromHtml(value).trim();
  }),
});

const getObjectNameValues = (values) => (
  Object.keys(values).reduce((acc, item) => {
    return values[item].is_selected
      ? [...acc, +item]
      : acc;
  },[])
);

export const prepareCategoryRequestData = (values, prevVal, isEdit) => {
  const preparedData = {};
  const hasTagsAndCategories = {
    tags: true,
    categories: true,
  };

  Object.keys(values).forEach((item) => {
    switch(item) {
      case CATEGORIES:
        const preparedCategories = getObjectNameValues(values.categories);
        if (preparedCategories?.length === 0) hasTagsAndCategories.categories = false;
        if (isEqual(values.categories, prevVal.categories)) break;
        preparedData.categories = preparedCategories;
        break;
      case TAGS:
        const preparedTags = getObjectNameValues(values.tags);
        if (preparedTags?.length === 0) hasTagsAndCategories.tags = false;
        if (isEqual(values.tags, prevVal.tags)) break;
        preparedData.tags = preparedTags;
        break;
      case IS_VISIBLE:
        if (values[item] === prevVal[item]) break;
        preparedData[item] = values[item] === undefined ? false : values[item];
        break;
      case BG_FILE:
        if ((values[item] === prevVal[item] || values[item] === "") && !isEdit) break;
        if (values[item] === "") {
          preparedData[item] = values[item];
          break;
        } else if (values[item] !== prevVal[item]) {
          preparedData[item] = values[item];
        };
        break;
      case PROGRAM_TEMPLATE:
        if (isEdit) preparedData[item] = values[item];
        break;
      case PUBLIC_DESCRIPTION:
        if (values[item] === prevVal[item]) break;
        if (values[item]) preparedData[item] = values[item];
        break;
      default:
        if (values[item]) preparedData[item] = values[item];
        break;
    };
  });

  return { preparedData, hasTagsAndCategories };
};


export const transformArrayToObject = (items, type) => {
  const result = {};

  items.forEach((item) => {
    const categoryObj = {};
    categoryObj.title = item.title;
    categoryObj.is_selected = item.is_selected;

    if (type !== CATEGORIES) {
      result[item.id] = categoryObj;
    };

    if (item.sub_categories) {
      item.sub_categories.forEach(subCategory => {
        const subCategoryObj = {};
        subCategoryObj.title = subCategory.title;
        subCategoryObj.is_selected = subCategory.is_selected;

        result[subCategory.id] = subCategoryObj;
      });
    };
  });

  return result;
};

export const setValuesToSessionStorage = (values, prevValues) => {
  const keys = Object.keys(values);

  keys.forEach((key) => {
    if (key === BG_FILE && values[key] !== prevValues.current[key] && typeof values[key] !== "string") {
      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result;
        sessionStorage.setItem(BG_FILE, fileContent);
      };
      return reader.readAsDataURL(values.bg_file);
    };

    if (values[key] !== prevValues.current[key]) {
      if (typeof values[key] === "object") {
        return sessionStorage.setItem(key, JSON.stringify(values[key]));
      };
      sessionStorage.setItem(key, values[key]);
    };
  });
  prevValues.current = values;
};

export const getInitialStorageValues = (values) => {
  const res = {};
  const keys = Object.keys(values);

  keys.forEach((key) => {
    const item = sessionStorage.getItem(key);

    if ([TAGS, CATEGORIES, ID, IS_VISIBLE].some(i => i === key)) {
      return res[key] = item ? JSON.parse(item) : item;
    };
    res[key] = item;
  });

  return res;
};

export const isCheckboxVisible = ({
  isSubRow,
  subCategories,
  parentChecboxDosabled,
  row,
}) => {
  if (isSubRow) {
    return (parentChecboxDosabled || row.program_templates_count < 1);
  } else {
    return !(subCategories?.length > 2 && subCategories.every((category) =>
      category.program_templates_count > 0
    ));
  };
};

export const getInitialCheckboxState = (value) => {
  const isVisisble = sessionStorage.getItem(IS_VISIBLE);

  return (isVisisble && isVisisble !== undefined && isVisisble !== "undefined")
    ? JSON.parse(isVisisble)
    : value;
};
