import { createSlice } from "@reduxjs/toolkit";

const windowSlice = createSlice({
  name: "window",
  initialState: {
    size: {
      screenWidth: null,
      screenHeight: null,
      showHeader: false,
    },
    showHeader: true,
    showMenu: false,
  },
  reducers: {
    setWindowSize(state, action) {
      state.size = action.payload;
    },
    setShowMenu(state, action) {
      state.showMenu = action.payload;
    },
    setShowHeader(state, action) {
      state.showHeader = action.payload;
    },
  },
});

export const { setWindowSize, setShowMenu, setShowHeader } =
  windowSlice.actions;
export default windowSlice.reducer;
