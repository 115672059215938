import withCache from "../helpers/cache";

import myFetch from "./myFetch";

export const loadPaymentList = (params) => {
  !params.page && delete params.page;
  !params.search && delete params.search;

  const queryParams = new URLSearchParams(params).toString();

  return myFetch(`/api/invoices/payment-schedule/?${queryParams}`);
}
export const loadPaymentListWithCache = withCache(loadPaymentList);

export const loadCompanyPaymentList = async (id) => {
  const invoices = await myFetch(`/api/invoices/?company_id=${id}`);
  return { [id]: invoices };
};

export const loadCompanyPaymentListWithCache = withCache(
  loadCompanyPaymentList
);

export const loadEndUserPaymentList = async (id) => {
  const invoices = await myFetch("/api/invoices/");
  return { [id]: invoices };
};

export const loadEndUserPaymentListWithCache = withCache(
  loadEndUserPaymentList
);

export const loadPaymentInvoiceDays = async (id) => {
  const invoice = await myFetch(`/api/invoices/${id}/invoice-days/`);
  return { [id]: invoice };
};

export const loadPaymentInvoiceDaysWithCache = withCache(
  loadPaymentInvoiceDays
);

export const updatePayment = ({ id, body }) => {
  loadPaymentListWithCache.invalidate();
  return myFetch(`/api/invoices/${id}/`, {
    method: "PATCH",
    body: body,
  });
};
