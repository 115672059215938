import { SUPPORT_EMAIL } from "./common";

export const EError = {
  email_already_exists: `<div className="auth-error">
    Sorry. but an account with that email already exists.
    please use a different email or <a href=\"mailto:${SUPPORT_EMAIL}\" rel='nofollow' style='color:white;'>contact us</a> to delete
    your existing recomposer account.
  </div>`,
  default_error: "something went wrong",
  expired_error: "Sorry. The page you are trying to visit has expired.",
};
