import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FIRST_LOGIN } from "../../constants/router";
import { useWindowSize } from "../../hooks/useWindowSize";
import { setWindowSize } from "../../redux/windowSlice";
import Document from "../Document";
import Header from "../Header";
import Menu from "../Menu";
import Toastr from "../Toastr";

import "./MainLayout.scss";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const bgFile = useSelector(
    (state) => state.companies.currentCompany?.bg_file
  );
  const userCompany = useSelector((state) => state.user.user?.company);
  const bgRef = useRef(null);
  const { push } = useHistory();
  const user = useSelector((state) => state.user.user);
  const render = useRef(false);

  useEffect(() => {
    const isEndUser = user?.is_end_user;

    if (!user && !isEndUser && render.current) return;

    render.current = true;
    const toWelcomePage = isEndUser && !user?.is_program_exists;
    const toChooseProgram =
      isEndUser && user?.is_strength_exists && !user?.is_program_exists;

    if (toWelcomePage) push(`${FIRST_LOGIN.root}/${FIRST_LOGIN.welcomePage}`);
    else if (toChooseProgram)
      push(`${FIRST_LOGIN.root}/${FIRST_LOGIN.firstProgram}`);
  }, [user]);

  useEffect(() => {
    if (!userCompany || !bgFile) return;

    const bg = bgRef.current;

    bg.style.backgroundImage = `url(${bgFile})`;

    return () => {
      bg.style.backgroundImage = "";
    };
  }, [bgFile, userCompany]);

  useEffect(() => {
    dispatch(setWindowSize(windowSize));
  }, [windowSize]);

  /*TODO: Обернуть в Error Boundary (!)*/

  return (
    <>
      <Document>
        <div className="bg" ref={bgRef} data-cy={"background"} />
        <div className="main-layout">
          <Menu />
          <Header />
          <div className="main-layout__body">
            <div className="main-layout__content">{children}</div>
          </div>
        </div>
        <Toastr />
      </Document>
    </>
  );
};

export default MainLayout;
