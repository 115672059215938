import isEmpty from "lodash/isEmpty";
import moment from "moment";

import { UTC_FORMAT_ON_BACKEND } from "../constants/common";
import { coefficients } from "../constants/repCoefficients";
import {
  PB_DEFAULT_VALUE,
  MAX_PB_COEFFICIENT,
  MIN_PB_COEFFICIENT,
  PB_STEP_THRESHOLD,
  STEP_SMALL,
  STEP_BIG,
} from "../constants/training";

import {
  NO_WORKOUT_SCROLL_OFFSET,
  WORKOUT_SCROLL_OFFSET_TOP,
} from "../pages/Clients/ClientTrainingPage/const";

import { BasicHelper, groupByField, incToCondition } from "./index";
import { cloneDeep } from "lodash";

/**
 * Calculate number of goal repetitions
 * @param {number} pb personal best
 * @param {number} weight
 * @param {string} repCalculator type of the coefficients to use
 * @returns {number} repetitions
 */
export const calculateGoalReps = (pb, weight, repCalculator) => {
  const maxReps = coefficients[repCalculator]?.length ?? 0;
  if (!pb) return maxReps;
  return incToCondition(
    (reps) => reps >= maxReps || pb < calculateMax(weight, reps, repCalculator)
  );
};

export const getMergedId = (exerciseId, setId) => `${exerciseId}-${setId}`;

export const roundMax = (max) => BasicHelper.roundTo(max, max < 100);

export const stepCalculate = (pb) =>
  (pb <= PB_STEP_THRESHOLD) ? STEP_SMALL : STEP_BIG;

export const calculateNearestMultipleOfNumber = (number, multiple) =>
  Math.round(number / multiple) * multiple;

export const calculateGoalWeight = (pb, reps, repCalculator) => {
  const notNullPb = pb || PB_DEFAULT_VALUE;
  const step = stepCalculate(notNullPb);
  const maxWeight = notNullPb * MAX_PB_COEFFICIENT;
  const minWeight = notNullPb * MIN_PB_COEFFICIENT;
  const start = calculateNearestMultipleOfNumber(minWeight, step);

  return incToCondition(
    (weight) =>
      weight >= maxWeight ||
      notNullPb < calculateMax(weight, reps, repCalculator),
    start,
    step
  );
};

/**
 *
 * @param {number} weight
 * @param {number} reps number of repetitions
 * @param {string} repCalculator type of the coefficients to use
 * @returns {number} calculated max
 */
export const calculateMax = (weight, reps, repCalculator) => {
  // const repCalc =
  //   repCalculator === "legPressExtension"
  //     ? REP_CALCULATOR_TYPES.LEG_PRESS_EXT
  //     : repCalculator;
  // const coeff = coefficients[repCalc][reps - 1];
  if (coefficients[repCalculator] && reps > 0) {
    const coeff = coefficients[repCalculator][reps - 1];
    const value = weight * coeff;
    return roundMax(value) || 0;
  }
  return 0
};

/**
 * @param {number} currentPb current personal best
 * @param {number} weight weight as a percentage
 * @returns calculated current weight
 */
export const calculateWeight = (currentPb, weight) => {
  const pb = currentPb || PB_DEFAULT_VALUE;
  return Math.round((pb * weight) / 100);
};

/**
 *
 * @param {object} currentExercise
 * @param {number} currentSetIdx
 * @param {object} currentSet
 * @param {number} pb
 * @param {boolean} pbIsCalculated
 * @param {Object} template
 * @return {ComposedSet: Object}
 */
export const composeSetValues = (
  currentExercise,
  currentSetIdx,
  currentSet,
  pb,
  pbIsCalculated,
  template,
  isNewProgramAdded,
) => {
  const defaultSet = {
    weight: 20,
    reps: null,
    completed_at: null,
    notes: null,
    other_reps: 0,
    other_reps_notes: null,
    is_completed: false,
  };

  const defaultExercise = {
    day_index: 0,
    workout_index: 0,
    exercise_index: 0,
    rep_calculator: "Standard",
    exercise_name: "unspecified",
    exercise_id: 0,
  };

  const _currentSet = currentSet || defaultSet;
  const _currentExercise = currentExercise || defaultExercise;

  const {
    weight,
    reps,
    completed_at,
    notes,
    other_reps,
    other_reps_notes,
    is_completed,
  } = _currentSet;

  const {
    day_index,
    workout_index,
    exercise_index,
    rep_calculator,
    exercise_name,
    exercise_id,
  } = _currentExercise;

  const set = template?.exercises?.find((exercise) =>
    isEqualExercise(exercise, currentExercise)
  )?.sets[currentSetIdx];

  const calculatedWeight =
    weight && (is_completed || isNewProgramAdded ? weight : calculateWeight(pb, set?.weight));
  const calculatedReps =
    reps ?? calculateGoalReps(pb, calculatedWeight, rep_calculator);

  return {
    values: {
      weight: calculatedWeight || weight,
      reps: calculatedReps,
    },
    isCompleted: is_completed,
    id: currentSet?.id,
    day: day_index + 1,
    workoutIndex: workout_index + 1,
    exerciseName: exercise_name,
    exerciseId: exercise_id,
    exerciseIndex: exercise_index + 1,
    repCalculator: rep_calculator,
    pb: pb ?? null,
    pbIsCalculated: pbIsCalculated ?? true,
    set: {
      number: currentExercise?.sets.indexOf(currentSet) + 1,
      completed_at,
      ...set,
    },
    notes,
    other_reps,
    other_reps_notes,
  };
};

/**
 *
 * @param {array} exercises
 * @param {number} currentDayIdx
 * @param prev get not next, but previous
 * @returns {number || false}
 */
export const getNextDayIdx = (exercises, currentDayIdx, prev = false) => {
  const isRestDay = (day) =>
    !day.every((exercise) => exercise.workout_index === null);

  const groupedByDay = groupByField(exercises, "day_index");
  const prevDays = groupedByDay.slice(0, currentDayIdx);
  const nextDays = groupedByDay.slice(currentDayIdx + 1);
  const remainingDays = prev ? prevDays.reverse() : nextDays;

  const nextNotRestDay = remainingDays.find((d) => !isRestDay(d));
  return nextNotRestDay ? groupedByDay.indexOf(nextNotRestDay) : false;
};

/**
 * @param {Array} exercises
 * @returns {{ exercise: Object, set: Object, isToday: boolean, isRecent: boolean } || false}
 */
export const findInitial = (exercises) => {
  const now = moment();
  const exercisesReversed = cloneDeep(exercises).reverse();

  const checkIfHasCompletedSet = (sets) => {
    let stopSetSearching = false;
    return sets.reduce((acc, set, i) => {
      if (!set.is_completed) return acc;
      if (sets.length === i && set.is_completed && !stopSetSearching) {
        stopSetSearching = true;
        return { ...acc, stopSetSearching };
      };
      const age = now.diff(moment.utc(set.completed_at, UTC_FORMAT_ON_BACKEND).local());
      return { set, age, stopSetSearching };
    }, {
      set: null,
      age: Infinity,
    });
  };

  const findRecentExercise = (exercises) => {
    const res = exercises.reduce((acc, e) => {
      if (isEmpty(e.sets) || acc.exercise?.day_index > e.day_index) return acc;
      const { set, age, stopSetSearching } = checkIfHasCompletedSet(e.sets, e.day_index);
      if (age < acc.age && !stopSetSearching) {
        return {
          exercise: e,
          set: set,
          age: age,
          stopSetSearching,
          latestUntouched: acc.latestUntouched,
        }
      } else if (e.exercise_index === 0 && age === Infinity) {
        return {
          ...acc,
          latestUntouched: {
            exercise: e,
            set: set,
            age: age,
          }
        };
      } else return acc;
    },
    {
      exercise: null,
      set: null,
      age: Infinity,
      stopSetSearching: false,
      latestUntouched: null,
    });

    if (moment(res?.set?.completed_at, UTC_FORMAT_ON_BACKEND).isBefore(moment().format(UTC_FORMAT_ON_BACKEND), "day")) {
      const { newDay, newWorkout } = exercises.reduce((acc, exercise) => {
        const holder = {
          newDay: acc.newDay,
          newWorkout: acc.newWorkout
        };
        if (exercise.day_index === res.exercise?.day_index + 1) {
          holder.newDay = exercise;
        };

        if (exercise.day_index === res.day_index && exercise.wokout_index === res.wokout_index ) {
          holder.newWorkout = exercise;
        };
        return holder;
      }, { newDay: null, newWorkout: null });

      if (!newWorkout && !newDay) {
        return { closeAll: true }
      };

      if (newWorkout) {
        return {
          newWorkout,
          set: newWorkout.sets[0],
          age: moment().diff(moment.utc(newWorkout.sets[0].completed_at, UTC_FORMAT_ON_BACKEND).local()),
        };
      };

      if (!newDay.sets?.length && res.latestUntouched) {
        return {
          exercise: res.latestUntouched.exercise,
          set: res.latestUntouched.exercise.sets[0],
          age: Infinity,
        };
      };

      return {
        exercise: newDay,
        set: newDay.sets[0],
        age: null,
      };
    };

    return res;
  };

  const params = new URLSearchParams(window.location.search);
  const searchDay = params.get("day");
  const searchWorkout = params.get("workout") || 0;
  const searchExercise = params.get("exercise");
  const searchSet = params.get("set");
  const preselectedExercise = exercises.find((exercise) =>
    isEqualExercise(exercise, {
      day_index: parseInt(searchDay),
      workout_index: parseInt(searchWorkout),
      exercise_index: parseInt(searchExercise),
    })
  );
  const preselectedSet = preselectedExercise?.sets[searchSet];

  let initial;
  let isRecent;
  if (preselectedSet) {
    initial = {
      exercise: preselectedExercise,
      set: preselectedSet,
      age: now.diff(
        moment.utc(preselectedSet.date, UTC_FORMAT_ON_BACKEND).local()
      ),
    };
    isRecent = false;
  } else {
    initial = findRecentExercise(exercisesReversed);
    if (initial.closeAll) return initial;
    if (!initial?.exercise) return false;
    isRecent = true;
  }

  const isToday = now.diff(now.clone().startOf("day")) >= initial.age && initial.age !== null;
  return { isToday, isRecent, ...initial };
};

/**
 * @param {object} exercise
 * @param {number} exercise.day_index
 * @param {number} exercise.workout_index
 * @param {number} exercise.exercise_index
 * @param {object} targetExercise
 * @param {number} targetExercise.day_index
 * @param {number} targetExercise.workout_index
 * @param {number} targetExercise.exercise_index
 * @returns {boolean}
 */
export const isSubsequentExercise = (exercise, targetExercise) => {
  return (
    exercise?.day_index > targetExercise?.day_index ||
    (exercise?.day_index === targetExercise?.day_index &&
      (exercise?.workout_index > targetExercise?.workout_index ||
        (exercise?.workout_index === targetExercise?.workout_index &&
          exercise?.exercise_index > targetExercise?.exercise_index)))
  );
};

/**
 * @param {object} exercise
 * @param {number} exercise.day_index
 * @param {number} exercise.workout_index
 * @param {number} exercise.exercise_index
 * @param {number} exercise.exercise_id
 * @param {object} targetExercise
 * @param {number} targetExercise.day_index
 * @param {number} targetExercise.workout_index
 * @param {number} targetExercise.exercise_index
 * @param {number} targetExercise.exercise_id
 * @returns {boolean}
 */
export const isEqualExercise = (exercise, targetExercise) => {
  return (
    exercise?.day_index === targetExercise?.day_index &&
    exercise?.workout_index === targetExercise?.workout_index &&
    exercise?.exercise_index === targetExercise?.exercise_index &&
    exercise?.exercise_id === targetExercise?.exercise_id
  );
};

/**
 * @param {object} exercise
 * @param {number} exercise.exercise_id
 * @param {object} targetExercise
 * @param {number} targetExercise.exercise_id
 * @returns {boolean}
 */
export const isSameTypeExercise = (exercise, targetExercise) => {
  return exercise?.exercise_id === targetExercise?.exercise_id;
};

export const isNotUniqueExercise = (idx, selectedExercise) =>
  idx === selectedExercise?.idx;

export const getEditExerciseModalMessage = (exerciseName) => (
  <>
    <div className="training-page__modal-title">Warning:</div>
    There are already instances of "{exerciseName}" in the program. Are you sure
    you want to create additional instances of "{exerciseName}"?
  </>
);

export const hasSubsequentSimilarExercises = (exercises, selectedExerciseInfo) => {
  const { day_index, exercise_id } = selectedExerciseInfo;

  for (let i = day_index; i < exercises.length; i++) {
    if (exercises[i].exercise_id === exercise_id && i > day_index) return true;
  };

  return false;
};

/**
 *
 * @param {number} dayIdx
 * @param {number} programDaysCount
 * @returns {number} offset length
 */
export const getOffsetLength = ({ dayIdx, programDaysCount }) => {
  return dayIdx === programDaysCount - 1
    ? NO_WORKOUT_SCROLL_OFFSET
    : WORKOUT_SCROLL_OFFSET_TOP;
};
