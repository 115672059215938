import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import myFetch, { restorePasswordHandlerError } from "../api/myFetch";

export const restorePassword = createAsyncThunk(
  "forgotPassword/restore-password",
  (body) => {
    return myFetch(
      "/api/restore-password",
      { method: "POST", body: body },
      restorePasswordHandlerError
    );
  }
);

export const saveRestoredPassword = createAsyncThunk(
  "forgotPassword/saveRestoredPassword",
  (body) => {
    return myFetch("/api/new-password", {
      method: "POST",
      body: body,
    });
  }
);

const restorePasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    loading: false,
    success: false,
    successRestore: false,
    error: null,
  },
  reducers: {
    clearRestore(state) {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: {
    [restorePassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [restorePassword.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    [saveRestoredPassword.pending]: (state) => {
      state.loading = true;
      state.successRestore = false;
    },
    [saveRestoredPassword.fulfilled]: (state) => {
      state.loading = false;
      state.successRestore = true;
    },
    [saveRestoredPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [restorePassword.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error;
    },
  },
});

export const { setLogout, clearRestore } = restorePasswordSlice.actions;
export default restorePasswordSlice.reducer;
